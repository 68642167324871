<template>
<div class="pt-8 pl-2 pr-2">
    <div class="container">
        <v-menu v-model="menu1" 
            :close-on-content-click="false" :nudge-right="40"
            transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="date1" label="Fecha 1" prepend-icon="mdi-calendar" 
                readonly outlined dense v-bind="attrs" v-on="on" dark>
            </v-text-field>
          </template>
          <v-date-picker v-model="date1" @input="menu1 = false" locale="es">
          </v-date-picker>
        </v-menu>
        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" 
          transition="scale-transition" offset-y min-width="auto">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field v-model="date2" label="Fecha 2" prepend-icon="mdi-calendar" readonly outlined dense v-bind="attrs"v-on="on" dark>
            </v-text-field>
          </template>
          <v-date-picker v-model="date2" @input="menu2 = false" locale="es"></v-date-picker>
        </v-menu>
        <v-select dark v-model="user" :items="operators" item-text="name" item-value="id"
           dense label="Operadores" outlined v-on:change="filtrar">
        </v-select>
        <v-btn rounded :disabled="disable" :loading="loading" @click="buscar">Buscar</v-btn>
    </div>           
    <div class="text-center"><v-chip class="ma-2" color="green" text-color="white" large>
        <v-avatar left class="green darken-4">Bs</v-avatar>{{ total }}</v-chip>
    </div>
    <div class="">
        <v-btn :loading="loadingpdf" @click="downloadPdf" class=primary>Descarga PDF<v-icon right dark>mdi-file-pdf-box</v-icon></v-btn>
        <v-btn :loading="loadingExcel" @click="downloadExcel" class="secondary">Descarga Excel<v-icon right dark>mdi-microsoft-excel</v-icon></v-btn>
    </div>
    <v-sheet 
        v-if="!ingresos" 
        :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`"
        class="px-3 pt-3 pb-3">
        <v-skeleton-loader 
            class="mx-auto"
            max-width="1200"
            type="table-heading, table-thead, table-tbody, table-tfoot">
        </v-skeleton-loader>
    </v-sheet>    
    <v-simple-table dense v-else >
        <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">##</th>
                    <th class="text-left">Ingreso</th>
                    <th class="text-left">Salida</th>
                    <th class="text-left">Tiempo</th>
                    <th class="text-left">Parqueo</th>
                    <th class="text-left">Lugar</th>
                    <th class="text-left">Placa</th>
                    <th class="text-left">Tarifa(bs)</th>
                    <th class="text-left">Promo</th>
                    <th class="text-left">O.Ingr</th>
                    <th class="text-left">O.Sal</th>
                    <th class="text-right">Total(bs)</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="ingreso in ingresos" :key="ingreso.id">
                    <td>{{ ingreso.index }}</td>
                    <td>{{ formatBasic(ingreso.created_at) }}</td>
                    <td>{{ ingreso.salida }}</td>
                    <td>{{ diference(ingreso.created_at, ingreso.salida) }}</td>
                    <td>{{ ingreso.place.parking.title }}</td>
                    <td>{{ ingreso.place.number }}</td>
                    <td style="text-transform: uppercase;">{{ ingreso.placa }}</td>
                    <td>{{ ingreso.tarifa }}</td>
                    <td>{{ ingreso.promo }}</td>
                    <td>{{ ingreso.user.name }}</td>
                    <td>{{ ingreso.outuser }}</td>
                    <td class="text-right">{{ ingreso.importe }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>

    <template v-if="ingresos!=null">
        <div class="pagination pa-4" v-if="ingresos.length<pagination.total">
            <v-btn 
                :disabled="pagination.prev_page_url==null"
                x-small fab color="white"
                @click="byPage(pagination.current_page-1)"
            >
                <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <div v-for="(page, index) in pagination.last_page" :key="page">                
                <v-btn
                    v-if="(pagination.current_page)===index+1" 
                    x-small fab color="primary"
                >
                    {{ index +1}}
                </v-btn>                
                <v-btn
                    v-else
                    x-small fab color="white"
                    @click="byPage(index+1)"
                >
                    {{ index +1}}
                </v-btn>  
            </div>
            <v-btn 
                :disabled="pagination.current_page==pagination.last_page"
                x-small 
                fab 
                color="white"
                @click="byPage(pagination.last_page)"
            >
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </div>
    </template>
    
    <!-- <pre>{{ pagination }}</pre> -->
</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { mixDate } from '@/mixins/mixDate.js'
export default {
    inject: ["theme"],
    name: 'Ingresos',
    mixins: [mixDate],
    data() {
        return {
            pagination:{},
            date1: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            date2: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu1: false,
            menu2: false,
            disable: false,
            loading: false,
            user: 0,
            operators: [],
            loadingpdf: false,
            total: 0,
            loadingExcel: false
        }
    },
    computed: {
        ...mapGetters(['ingresos']),
        getUrl(){
            return 'https://direvian.com/service-parkings/public/api/report/pdf?date1='+this.date1+'&date2='+this.date2+'&user='+this.user
        },
    },
    methods: {
        ...mapActions(['retrieveIngresos', 'downloadPDF', 'downloadEXCEL']),
        buscar(){
            this.loading=true
            this.retrieveIngresos({ 
                start: this.date1,
                end: this.date2,
                user: this.user,
                page: 1
            }).then(response=>{
                this.pagination = response.pagination
                this.total = response.total
                this.loading=false
            })
        },
        filtrar(){},
        downloadPdf(){
            this.loadingpdf=true
            this.downloadPDF({
                start: this.date1,
                end: this.date2,
                user: this.user
            }).then(resp=>{
                this.loadingpdf=false
                const url = window.URL.createObjectURL(new Blob([resp], {type: 'application/pdf'}))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'reporte.pdf')
                document.body.appendChild(link)
                link.click()
            })
        },
        byPage(page){
            this.loading=true
            this.retrieveIngresos({ 
                start: this.date1,
                end: this.date2,
                user: this.user,
                page: page
            }).then(response=>{
                this.pagination = response.pagination
                this.loading=false
            })
        },
        downloadExcel(){
            this.loadingExcel=true
            this.downloadEXCEL({
                start: this.date1,
                end: this.date2,
                user: this.user
            }).then(resp=>{
                this.loadingExcel=false
                const url = window.URL.createObjectURL(new Blob([resp], {type: 'application/excel'}))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'reporte-excel.xlsx')
                document.body.appendChild(link)
                link.click()
            })
        }
    },
    watch: {
        date1(newValue, oldValue) {
            const d1 = new Date(newValue)
            const d2 = new Date(this.date2)
            if(d1.getTime()>d2.getTime()){
                this.disable=true
            }else{
                this.disable=false
            }
        },
        date2(newValue, oldValue) {
            const d1 = new Date(this.date1)
            const d2 = new Date(newValue)
            if(d1.getTime()>d2.getTime()){
                this.disable=true
            }else{
                this.disable=false
            }
        },
    },
    created(){
        this.retrieveIngresos({
            start: '',
            end: '',
            user: 0,
            page: 1
        }).then(response=>{            
            this.pagination = response.pagination
            this.total = response.total
            this.operators = response.operators
            this.operators.unshift({
                id: 0,
                name: 'TODOS',
                email: '--'
            })
        })
    },
}
</script>
<style lang="scss" scoped>
    .container{ display: flex; width: 50%; }
    .pagination{
        display: flex;
        background-color: #fff;
    }
</style>