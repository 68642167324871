<template>
    <v-container>
        <v-card color="pa-4">
            <v-card-title>Promociones </v-card-title>
            <v-card-text>
                <v-row class="d-flex aling-center">
                    <v-col class="d-flex justify-start" cols="5">
                        <v-select
                        v-model="tarifa"
                        :items="tarifasPromo"
                        item-text="name_tarifa"
                        item-value="id"
                        dense
                        label="Tarifas"
                        outlined
                        v-on:change="filtrar"
                        ></v-select>
                    <v-btn @click="reset">
                        <v-icon>mdi-reload</v-icon>
                    </v-btn>
                    <!-- <v-tooltip right>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="tarifa==null" @click="desactivar"
                            v-bind="attrs"
                            v-on="on">
                            <v-icon>mdi-stop</v-icon>
                            </v-btn>
                        </template>
                        <span>Desactivar Todos</span>
                        </v-tooltip> -->
                    </v-col>
                    <v-col class="d-flex justify-end">
                        <FormNewPromo 
                        :tarifas="tarifasPromo"
                        @promoAdded="showPromoAdded"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <PromoTable :promociones="promotions"/>
            </v-card-text>
        </v-card>
        <v-snackbar v-model="snackbar" :timeout="6000" bottom color="success">
           <p class="title white--text float-left">{{ mensaje }}</p>
           <v-btn small icon class="darkpage white--text float-right"
            fab @click="snackbar=false">x</v-btn>
        </v-snackbar>
    </v-container>
</template>
<script>
import PromoTable from '@/components/admin/promos/PromoTable'
import FormNewPromo from '@/components/admin/promos/FormNewPromo'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Promociones',
    data() {
        return {
            tarifa: null,
            snackbar: false,
            mensaje: '',
            promos: []
        }
    },
    components: {
        PromoTable,
        FormNewPromo
    },
    computed: {
        ...mapGetters(['tarifasPromo', 'promotions']),
    },
    methods: {
        ...mapActions(['retrieveTarifas', 'retrievePromos', 'filtrarPromotions']),
        showPromoAdded(){
            this.mensaje = 'Cliente Asignado a Promoción'
            this.snackbar = true
        },
        filtrar(){
            this.filtrarPromotions(this.tarifa)
        },
        reset(){
            this.tarifa = null
            this.filtrarPromotions(0)
        },
        desactivar(){  }
    },
    created() {
        this.retrievePromos().then( ()=>{
            if(this.tarifasPromo.length==0){
                this.retrieveTarifas()
            }
        })

        this.$root.$on("promoUpdated", () => {
            this.mensaje = 'Cliente Promoción actualizados'
            this.snackbar = true
        })
    },
}
</script>
<style lang="scss" scoped></style>