<template>
  <v-container fluid>
    <v-card height="100vh">
      <v-card-title> Mi Cuenta </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6" sm="6">
            <FormUserData />
          </v-col>
          <v-col cols="12" md="6" sm="6">
            <FormUserPassword />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="6000" bottom color="success">
      <p class="title white--text float-left">{{ mensaje }}</p>
      <v-btn
        small
        icon
        class="darkpage white--text float-right"
        fab
        @click="snackbar = false"
        >x</v-btn
      >
    </v-snackbar>
  </v-container>
</template>
<script>
import { mapActions } from "vuex"
import FormUserData from "@/components/admin/users/FormUserData"
import FormUserPassword from "@/components/admin/users/FormUserPassword"

export default {
  name: "Profile",
  components: {
    FormUserData,
    FormUserPassword
  },
  data() {
    return {
      snackbar: false,
      mensaje: "",
      user: {
        id: 0,
        name: "",
        email: "",
      },
    };
  },

  methods: {
    ...mapActions(["retrieveActiveUser"]),
    change() {},
  },

  created() {
    this.retrieveActiveUser().then((resp) => {
      Object.assign(this.user, resp)
      this.$root.$emit('setFormDataProfile', this.user)
    })
  },
}
</script>

<style lang="scss" scoped>
</style>