<template>  
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
    >
      <v-card>
        <v-card-title class="darkpage white--text">
          <span class="text-h5">Editar Contrato Pre Pago</span>
        </v-card-title>
        <v-card-text>
          <v-row class="pa-4">
            <v-col cols="12" sm="6" md="6">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-row dense>
                  <v-col cols="12" sm="8" md="8">
                    <v-text-field
                      v-model="models.name"
                      label="Nombre Completo"
                      :rules="requiredRule"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="models.ci"
                      label="ID Usuario"
                      :rules="requiredRule"
                      outlined
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="models.mobile"
                      label="Celular"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="models.email"
                      label="E-mail"
                      type="email"
                      outlined
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                        v-model="dateMenu1"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="models.start"
                            label="Fecha Inicio"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            :rules="requiredRule"
                            readonly
                            outlined
                        ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="models.start"
                          @input="dateMenu1 = false"
                          locale="es"
                          change="changeStart"
                        ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                        v-model="dateMenu2"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="models.end"
                            label="Fecha Fin"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            :rules="requiredRule"
                            readonly
                            outlined
                        ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="models.end"
                          @input="dateMenu2 = false"
                          locale="es"
                          change="changeEnd"
                        ></v-date-picker>
                    </v-menu>
                  </v-col>
  
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                        v-model="models.turno"
                        :items="turnos"
                        label="Turno"
                        :rules="requiredRule"
                        outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="models.chip"
                      label="No Contrato"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="models.monto"
                      :rules="requiredRule"
                      label="Monto a Pagar"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6"></v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <VehiclesTableEdit 
                  :vehicles="vehicles"
                  @updateVehicles="setVehicles"
              />
            </v-col>
          </v-row>            
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn            
            text
            @click="cleanData"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            class="darkpage--text"
            rounder
            :loading="loading"
            :disabled="!valid"
            @click="submit"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar" :timeout="6000" bottom color="error">
          <p class="title white--text float-left">{{ mensaje }}</p>
          <v-btn
              small
              icon
              class="darkpage white--text float-right"
              fab
              @click="snackbar = false"
          >x</v-btn>
      </v-snackbar>

    </v-dialog>  
</template>

<script>
import { mapActions } from 'vuex'
import VehiclesTableEdit from '@/components/admin/prepagos/VehiclesTableEdit'

    export default {
        name: "FormEditPrePago",
        data() {
            return {
                dialog: false,
                valid: false,
                lazy: false,
                loading: false,
                dateMenu1: false,
                dateMenu2: false,
                turnos: ['24 horas', 'dia', 'tarde'],
                tipo: 'Mensual',
                tipos: ['Mensual', 'Semestral', 'Anual'],
                vehicles: [],
                models:{
                  name: '',
                  mobile: 0,
                  ci: '',
                  chip: '',
                  email: '',
                  start: null,
                  end: null,
                  turno: '',
                  monto: 0,
                  id: 0
                },
                requiredRule: [(v) => !!v || "Este Campo es requerido"],
                emailRules: [
                  (v) => /.+@.+/.test(v) || "E-mail must be valid",
                ],
                snackbar: false,
                mensaje: ''
            }
        },
        components: {
            VehiclesTableEdit
        },
        methods: {
          ...mapActions(['updateContract', 'addVehicleToContract']),
            submit(){
              if(this.valid){
                if(this.vehicles.length>0){
                  this.loading = true
                  

                  const fdata = {
                    name: this.models.name,
                    ci: this.models.ci,
                    mobile: this.models.mobile,
                    email: this.models.email,
                    start: this.models.start,
                    end: this.models.end,
                    turno: this.models.turno,
                    chip: this.models.chip,
                    monto: this.models.monto,
                    id: this.models.id
                  }

                  this.updateContract(fdata).then( ()=>{
                    this.loading = false
                    this.dialog = false
                    //todo algun emit SNACKBAR
                  })

                }else{
                  this.valid = false
                  this.snackbar = true
                  this.mensaje = 'Al menos un Vehiculo es requerido '
                }
              }
            },
            cleanData(){
              this.dialog = false
            },
            setVehicles(id){
              this.vehicles = this.vehicles.filter(vehi=>vehi.id!==id)
            }, 
            changeStart(){
              let hrs = new Date().getHours()
              if(hrs<=9){ hrs = '0'+hrs }

              let mn = new Date().getMinutes()
              if(mn<=9){ mn = '0'+mn }

              let sec = new Date().getSeconds()
              if(sec<=9){ sec = '0'+sec }

              const horas = hrs+':'+mn+':'+ sec
              this.models.start = this.models.start + horas
            },
            changeEnd(){
              let hrs = new Date().getHours()
              if(hrs<=9){ hrs = '0'+hrs }

              let mn = new Date().getMinutes()
              if(mn<=9){ mn = '0'+mn }

              let sec = new Date().getSeconds()
              if(sec<=9){ sec = '0'+sec }

              const horas = hrs+':'+mn+':'+ sec
              this.models.end = this.models.end + horas
            },
        },
        created() {

            this.$root.$on("setContractData", (contract) => {
                this.models.id = contract.id
                this.models.name = contract.client.name
                this.models.ci = contract.client.ci
                this.models.mobile = contract.client.mobile
                this.models.email = contract.client.email
                this.models.start = contract.start
                this.models.end = contract.end
                this.models.turno = contract.turno
                this.models.chip = contract.chip
                this.models.monto = contract.monto
                this.vehicles = [...contract.vehicles]
                this.dialog = true
            })

            this.$root.$on("addVehiclesToList", (data) => {
                const place = this.vehicles.find(vehi=>vehi.place_id==data.place_id)            
                if(place==undefined){
                    data.contract_id = this.models.id
                    this.addVehicleToContract(data).then( (resp)=>{                      
                      this.vehicles.push(resp)
                      this.valid = true
                    })

                }else{
                    this.mensaje = 'Lugar ya se encuentra ocupado'
                    this.snackbar = true
                }
            })

            this.$root.$on("vehiclesRemoved", (index) => {
                this.vehicles.splice(index, 1)
            })
	  	},
    }
</script>

<style lang="scss">
</style>