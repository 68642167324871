<template>
  <v-dialog v-model="dialog" persistent width="400">
    <template v-slot:activator="{ on, attrs }">
      <v-btn x-small fab v-bind="attrs" v-on="on">
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="accent white--text">
        <span class="text-h5">Editar Cliente con Promoción</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-col cols="12">
                <v-text-field
                  v-model="placa"
                  label="No Placa"
                  :rules="[(v) => !!v || 'Placa es requerida']"
                  @input="placa = placa.toUpperCase()"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-select
                  v-model="tarifa"
                  :items="tarifasPromo"
                  :rules="[(v) => !!v || 'Placa es requerida']"
                  label="Tarifa"
                  item-text="name_tarifa"
                  return-object
                  outlined
                ></v-select>
              </v-col>
              <v-col>
                <v-switch
                  v-model="statePromo"
                  color="secondary"
                  v-on:change="changeText"
                  :label="statePromoText"
                ></v-switch>
              </v-col>
            </v-form>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="darkpage--text" color="grey-4" @click="dialog = false">
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          class="darkpage--text"
          rounder
          @click="submit"
          :loading="loading"
          :disabled="!valid"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FormEditPromo",
  props: {
    promo: {
      type: Object,
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      lazy: false,
      tarifa: null,
      placa: "",
      loading: false,
      disabled: false,
      statePromo: false,
      statePromoText: "Inactivo",
    };
  },
  computed: {
    ...mapGetters(["tarifasPromo"]),
  },
  methods: {
    ...mapActions(["updatePromo"]),
    changeText() {
      this.statePromo? this.statePromoText='Activo': this.statePromoText='Inactivo'      
    },
    submit() {
      if (this.valid) {
        const fdata = {
          num_placa: this.placa,
          tarifa_id: this.tarifa.id,
          state: this.statePromo,
          id: this.promo.id,
        }

        this.loading = true;
        this.updatePromo(fdata).then(() => {
          this.loading = false
          this.$refs.form.resetValidation()
          this.$root.$emit("promoUpdated")
          this.dialog = false
        })
      }
    },
  },

  created() {
    this.placa = this.promo.num_placa
    this.tarifa = this.promo.tarifa

      
    if (this.promo.state_promo == "active") {
      this.statePromo = true
      this.statePromoText = "Activo"
    } else {
      this.statePromo = false
      this.statePromoText = "Inactivo"
    }
  },
}
</script>

<style lang="scss" scoped></style>
