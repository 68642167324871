<template>
    <v-card height="380" class="ing-card">
        <v-card-title>
            Ingreso: <v-chip> {{ formatoSoloFecha(today) }} </v-chip>
        </v-card-title>
        <div class="total">
            <div class="amount">
                {{total}}
                <span>Bs</span>
            </div>
        </div>
        <v-card-actions>
            <v-btn rounded to="/ingresos">Detalle</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { mixDate } from '@/mixins/mixDate.js'

    export default {
        name: 'DashInresoDia',
        data() {
            return {
                today: Date.now(),
                total: 0
            }
        },

        computed: {
            ...mapGetters(['ticketsHoy']),
        },

        created () {
            setTimeout(() => {
                this.ticketsHoy.forEach(elem=> 
                   this.total = this.total + elem.importe
                )
            }, "2000")
        },

        mixins: [mixDate],

    }
</script>

<style lang="scss">
    .ing-card{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .total{
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: crimson;
            .amount{
                /* border-radius: 50%; */
                color: #fff; 
                font-size: 5em;
                padding: 55px 20px;
                span{
                    font-size: .3em;
                    background-color: #474a4f;
                    border-radius: 50%;
                    padding: 15px 15px;
                }
            }
        }
    }
</style>