<template>
  <v-app-bar app clipped-right class="elevation-0">
    <v-app-bar-nav-icon @click="showHide" />
    <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
      <span class="skyblue--text">Admin Parqueo</span>
    </v-toolbar-title>
    <v-spacer></v-spacer>

    <div class="font-weight-light blackblue--text px-2">NOMBRES</div>
    <v-avatar>
      <!-- <img
            v-if="user.foto == null || user.foto == ''"
            src="@/assets/profile.png"
            alt=""
          />
          <img v-else :src="user.foto"  /> -->
    </v-avatar>

    <v-menu left bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </template>

      <v-list>
        <v-list-item @click="goProfile">
          <v-list-item-title>Mi Cuenta</v-list-item-title>
        </v-list-item>
        <v-list-item @click="salir">
          <v-list-item-title>Salir</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppBar",
  props: {
      drawer: Boolean
  },

  methods: {
    goProfile() {
      this.$router.push("/profile")
    },

    salir() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login")
      })
    },

    showHide(){
        this.$emit("showHide")
    }

  },
}
</script>

<style>
</style>