<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="6" md="4">
        <v-card class="orange darken-1 elevation-12 login-form" height="40vh" dark>
          <v-card-text>
            <v-form>
              <v-text-field                
                append-icon="mdi-account"
                name="login"
                label="Login"
                type="text"
                v-model="userName"
                :error="error"
                :rules="[rules.required]"
                autocomplete="off"                
              ></v-text-field>

              <v-text-field
                :type="hidePassword ? 'password' : 'text'"
                :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
                name="password"
                label="Password"
                id="password"
                :rules="[rules.required]"
                v-model="password"
                :error="error"
                @click:append="hidePassword = !hidePassword"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn block class="white brown--text" @click="login" :loading="loading">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="showResult" :timeout="2000" top>{{ result }}</v-snackbar>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  props: {
    source: String
  },
  data() {
    return {
      loading: false,
      userName: "",
      password: "",
      hidePassword: true,
      error: false,
      showResult: false,
      result: "",
      rules: {
        required: value => !!value || "Required."
      }
    }
  },

  methods: {
    login() {
      this.loading = true
      if (!this.userName || !this.password) {
        this.result = "Email y Password required"
        this.showResult = true
        this.loading = false
        return
      }

      this.$store.dispatch("login", {
          email: this.userName,
          password: this.password
        })
        .then(() => {
          this.loading = false
          this.$router.push("/")
        })
        .catch(err => {
          this.loading = false
          this.error = true
          this.result = "Email o Password no son  Correctos."
          this.showResult = true
        })
    }
  },
  created () {
    this.$root.$on("validUser", (user) => {
      this.result = "Bienvenido "+user.nombres+", ingresa al sistema";
      this.showResult = true;
    })
  },
}
</script>

<style>
  .login-form{
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
</style>