<template>
<v-container>
    <v-card color="pa-4">
        <v-card-title>Tarifas</v-card-title>
        <v-card-text>
            <v-row class="d-flex aling-center">
                <v-col class="d-flex justify-start" cols="6"></v-col>
                <v-col class="d-flex justify-end">
                    <FormNewTarifa/>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <TarifaTable :tarifas="tarifas"/>
        </v-card-text>
    </v-card>
    <PoputMsg ref="poput"/>
</v-container>
</template>
<script>
import TarifaTable from '@/components/admin/tarifas/TarifaTable'
import FormNewTarifa from '@/components/admin/tarifas/FormNewTarifa'
import PoputMsg from '@/components/PoputMsg'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Tarifas',
    components: {
        TarifaTable,
        FormNewTarifa,
        PoputMsg
    },
    methods: {...mapActions(['retrieveTarifas']),
    },
    computed: {...mapGetters(['tarifas']) },
    created () {
        this.retrieveTarifas()
    },
}
</script>
<style lang="scss" scoped></style>