<template>  
    <v-dialog
      v-model="dialog"
      persistent
      width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
            x-small
            fab
            v-bind="attrs"
            v-on="on"
        >
            <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="secondary white--text">
          <span class="text-h5">Editar Tarifa</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="name_tarifa"
                    label="Nombre Tarifa"
                    :rules="requiredRule"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="costo"
                    label="Costo"
                    type="number"
                    min="0"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    v-model="type_tarifa"
                    row
                  >
                    <v-radio
                      label="Auto"
                      value="auto"
                    ></v-radio>
                    <v-radio
                      label="Moto"
                      value="moto"
                    ></v-radio>
                  </v-radio-group>
                </v-col>  
                <v-col cols="8"></v-col>  

                <v-col cols="12">
                    <v-switch
                    v-model="state"
                    :label="stateText"
                    v-on:change="changeText"
                    color="secondary"
                    ></v-switch>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="darkpage--text"
            text
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            class="darkpage--text"
            rounder
            :loading="loading"
            @click="addTarifa"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
</template>

<script>
  import { mapActions } from 'vuex'
    export default {
        name: 'FormEditPromo',
        props: {
            tarifa: {
                type: Object,
            },
        },
        data() {
          return {
            dialog: false,
            name_tarifa: '',
            costo: 0,
            requiredRule: [(v) => !!v || "Este Campo es requerido"],
            inputRules: [
              (v) => v.length >= 1 || "Minimo es 0",
            ],
            loading: false,
            lazy: false,
            valid: false,
            type_tarifa: 'auto',
            state: false,
            stateText: 'Activo'
          }
        },
        methods: {
          ...mapActions(['updateTarifa']),
          addTarifa() {
            if(this.valid){
              if(this.costo==''){
                this.costo=0
              }
              this.loading = true
              let estado = 'inactive'
              if(this.state){
                estado = 'active'
              }

              const data = {
                name_tarifa: this.name_tarifa,
                costo: this.costo,
                type_tarifa: this.type_tarifa,
                state_tarifa: estado,
                id: this.tarifa.id
              }
              this.updateTarifa(data).then( ()=>{
                this.loading = false
                this.$root.$emit('showMsg',{
                  mensaje: 'Tarifa actualizada con Exito',
                  clase: 'success'
                })
                this.dialog = false
              })
            }
          },
          changeText(){
            if(this.state){
                this.stateText = 'Activo'
            }else{
                this.stateText = 'Inactivo'
            }
          }
        },

        created () {
            this.name_tarifa = this.tarifa.name_tarifa
            this.costo = this.tarifa.costo
            this.type_tarifa = this.tarifa.type_tarifa
            if(this.tarifa.state_tarifa=='active'){
                this.state = true
                this.stateText = 'Activo'
            }else{
                this.state = false
                this.stateText = 'Inactivo'
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>