<template>  
    <v-dialog
      v-model="dialog"
      persistent
      width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="accent"
          dark
          fab
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="secondary white--text">
          <span class="text-h5">Nuevo Usuario</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="name"
                    label="Nombre Completo"
                    :rules="requiredRule"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    label="UserName"
                    :rule="requiredRule"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="clave"
                    label="Password"
                    type="password"
                    autocomplete="off"
                    :rules="[
                      (v) => !!v || 'Password es un campo requerido',
                      (v) => v.length >= 6 || 'Minimo 6 caracteres',
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="repetir_clave"
                    label="Repetir Clave"
                    type="password"
                    autocomplete="off"
                    :rules="[
                      clave === repetir_clave || 'Passwords no coiciden',
                      (v) => v.length >= 6 || 'Minimo 6 caracteres',
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-radio-group
                    v-model="rol"
                    row
                    dense
                  >
                    <v-radio
                      label="Operador"
                      value="control"
                    ></v-radio>
                    <v-radio
                      label="Admin"
                      value="admin"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
  
                <v-col cols="8"></v-col>  
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="darkpage--text"
            @click="dialog = false"
            text
            small
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            class="darkpage--text"
            :loading="loading"
            @click="addUser"
            rounder
            small
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
</template>

<script>
  import { mapActions } from 'vuex'
    export default {
        name: 'FormNewUser',
        data() {
          return {
            dialog: false,
            name: '',
            email: '',
            clave: '',
            repetir_clave: '',
            rol: 'control',
            requiredRule: [(v) => !!v || "Este Campo es requerido"],
            loading: false,
            lazy: false,
            valid: false,
          }
        },
        methods: {
          ...mapActions(['createUser']),
          addUser() {
            if(this.valid){
              this.loading = true
              const data = {
                name: this.name,
                email: this.email,
                rol: this.rol,
                state: 'active',
                password: this.clave,
                password_confirmation: this.clave
              }
              this.createUser(data).then( ()=>{
                this.loading = false
                this.name = ''
                this.email = 0
                this.rol = 'control'
                this.clave = ''
                this.repetir_clave = ''
                this.$root.$emit('showMsg',{
                  mensaje: 'Usuario creada con Exito',
                  clase: 'success'
                })
                this.dialog = false
              })
            }
          }
        },
    }
</script>

<style lang="scss" scoped>

</style>