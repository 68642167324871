<template>
    <v-card>
        <v-card-title class="caption">Boleta:&nbsp;
            <v-switch
                v-model="edit"
                color="success"
                hide-details
                label="Editar"
            ></v-switch>
        </v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-row dense>
                    <v-col cols="6" v-if="printsetup!=null" class="d-flex justify-center">
                        <v-card class="blue-grey lighten-5 pprinter" height="300" width="300" >
                            <div :style="`font-weight: ${printsetup.header.fontWeight};
                                text-align: ${printsetup.header.textAlign};
                                font-size: ${printsetup.header.fontSize};`"
                            >Parqueos Demo</div>
                            
                            <div :style="`text-decoration-line: ${printsetup.phone.textDecoration};
                                text-align: ${printsetup.phone.textAlign};
                                font-size: ${printsetup.phone.fontSize};`">4505855</div>
                            <div :style="`font-weight: ${printsetup.ingreso.fontWeight};
                                text-align: ${printsetup.ingreso.textAlign};
                                font-size: ${printsetup.ingreso.fontSize};`">(ingreso)</div>
                            <div :style="`font-weight: ${printsetup.placa.fontWeight};
                                text-align: ${printsetup.placa.textAlign};
                                font-size: ${printsetup.placa.fontSize};text-transform: uppercase`">CHD-23U</div>
    
                            <div style="display: flex; justify-content: center; align-items: center;">
                                <table>
                                    <tr>
                                        <td :style="`font-weight: ${printsetup.parqueo.fontWeight};
                                            text-align: ${printsetup.parqueo.textAlign};
                                            font-size: ${printsetup.parqueo.fontSize};`">planta baja
                                        </td>
                                    </tr>
                                    <tr>
                                        <td :style="`font-weight: ${printsetup.numero.fontWeight};
                                            text-align: ${printsetup.numero.textAlign};
                                            font-size: ${printsetup.numero.fontSize};`">10</td>
                                    </tr>
                                    <tfoot>
                                        <td :style="`font-weight: ${printsetup.fecha.fontWeight};
                                            text-align: ${printsetup.fecha.textAlign};
                                            font-size: ${printsetup.fecha.fontSize};`">
                                            Lunes 02 enero 2023
                                        </td>
                                    </tfoot>
                                </table>
                            </div>                            
                        </v-card>
                    </v-col>
                    <v-col cols="6" v-if="edit">
                        <textarea v-model="printsetupRaw" rows="10" cols="50"></textarea>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions v-if="edit">
            <v-spacer></v-spacer>
            <v-btn x-small text @click="proceed=true">Cancel</v-btn>
            <v-btn
                dark
                x-small 
                rounded
                color="success"
                :loading="loading"
                class="darkpage--text"
                @click="apply">Apply
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>    
import { mapGetters } from 'vuex'

    export default {        
        name: 'DashTicket',
        data() {
            return {
                valid: false,
                lazy: false,
                requiredRule: [(v) => !!v || "Este Campo es requerido"],
                loading: false,
                printsetup: null,
                printsetupRaw: '',
                edit: false
            }
        },

        computed: {
            ...mapGetters(['config']),
        },

        methods: {            
            async apply(){
                this.loading=true
                await this.$emit('updatePSetup', {
                    printsetup: this.printsetup
                })
                this.loading = false
                this.edit = false
            },
            /* apply(){
                this.printsetup = JSON.parse(this.printsetupRaw)
            } */
        },

        watch: {
            printsetupRaw(newValue, oldValue) {
                this.printsetup = JSON.parse(newValue)
            }
        },

        created (){
            setTimeout(() => {
                this.printsetup = JSON.parse(this.config.printsetup)
                //this.printsetupRaw = this.config.printsetup
                this.printsetupRaw = JSON.stringify(this.printsetup, null, 2);
            }, "2000")
        },
    }
</script>

<style lang="scss" scoped>
    .pprinter{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
</style>