<template>
<v-container>
    <v-card color="pa-4">
        <v-card-title>Usuarios</v-card-title>
        <v-card-text>
            <v-row class="d-flex aling-center">
                <v-col class="d-flex justify-start" cols="6"></v-col>
                <v-col class="d-flex justify-end">
                    <FormNewUser/>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-text>
            <UsersTable :users="users"/>
        </v-card-text>
    </v-card>
    <PoputMsg ref="poput"/>
    <ChangePassword />
</v-container>
</template>
<script>
import UsersTable from '@/components/admin/users/UsersTable'
import FormNewUser from '@/components/admin/users/FormNewUser'
import PoputMsg from '@/components/PoputMsg'
import ChangePassword from '@/components/admin/users/ChangePassword'
import { mapGetters, mapActions } from 'vuex'
export default {
    name: 'Users',
    components: { 
        UsersTable,
        FormNewUser,
        PoputMsg,
        ChangePassword
    },
    methods: {
        ...mapActions(['retrieveUsers'])
    },
    computed: {
        ...mapGetters(['users'])},
        created () {
            this.retrieveUsers()
        },
    }
</script>
<style lang="scss" scoped></style>