var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"caption"},[_vm._v("Boleta:  "),_c('v-switch',{attrs:{"color":"success","hide-details":"","label":"Editar"},model:{value:(_vm.edit),callback:function ($$v) {_vm.edit=$$v},expression:"edit"}})],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":_vm.lazy},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[(_vm.printsetup!=null)?_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"6"}},[_c('v-card',{staticClass:"blue-grey lighten-5 pprinter",attrs:{"height":"300","width":"300"}},[_c('div',{style:(`font-weight: ${_vm.printsetup.header.fontWeight};
                            text-align: ${_vm.printsetup.header.textAlign};
                            font-size: ${_vm.printsetup.header.fontSize};`)},[_vm._v("Parqueos Demo")]),_c('div',{style:(`text-decoration-line: ${_vm.printsetup.phone.textDecoration};
                            text-align: ${_vm.printsetup.phone.textAlign};
                            font-size: ${_vm.printsetup.phone.fontSize};`)},[_vm._v("4505855")]),_c('div',{style:(`font-weight: ${_vm.printsetup.ingreso.fontWeight};
                            text-align: ${_vm.printsetup.ingreso.textAlign};
                            font-size: ${_vm.printsetup.ingreso.fontSize};`)},[_vm._v("(ingreso)")]),_c('div',{style:(`font-weight: ${_vm.printsetup.placa.fontWeight};
                            text-align: ${_vm.printsetup.placa.textAlign};
                            font-size: ${_vm.printsetup.placa.fontSize};text-transform: uppercase`)},[_vm._v("CHD-23U")]),_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('table',[_c('tr',[_c('td',{style:(`font-weight: ${_vm.printsetup.parqueo.fontWeight};
                                        text-align: ${_vm.printsetup.parqueo.textAlign};
                                        font-size: ${_vm.printsetup.parqueo.fontSize};`)},[_vm._v("planta baja ")])]),_c('tr',[_c('td',{style:(`font-weight: ${_vm.printsetup.numero.fontWeight};
                                        text-align: ${_vm.printsetup.numero.textAlign};
                                        font-size: ${_vm.printsetup.numero.fontSize};`)},[_vm._v("10")])]),_c('tfoot',[_c('td',{style:(`font-weight: ${_vm.printsetup.fecha.fontWeight};
                                        text-align: ${_vm.printsetup.fecha.textAlign};
                                        font-size: ${_vm.printsetup.fecha.fontSize};`)},[_vm._v(" Lunes 02 enero 2023 ")])])])])])],1):_vm._e(),(_vm.edit)?_c('v-col',{attrs:{"cols":"6"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.printsetupRaw),expression:"printsetupRaw"}],attrs:{"rows":"10","cols":"50"},domProps:{"value":(_vm.printsetupRaw)},on:{"input":function($event){if($event.target.composing)return;_vm.printsetupRaw=$event.target.value}}})]):_vm._e()],1)],1)],1),(_vm.edit)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"x-small":"","text":""},on:{"click":function($event){_vm.proceed=true}}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"darkpage--text",attrs:{"dark":"","x-small":"","rounded":"","color":"success","loading":_vm.loading},on:{"click":_vm.apply}},[_vm._v("Apply ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }