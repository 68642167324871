
import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        options: {
          customProperties: true,
        },
        themes: {
          light: {
            primary: "#474a4f",
            secondary: "#7ac043",
            success: "#83ebb6",
            $accent: "#41cff3",
            error: "#f04647",
            darkpage: "#14151a",
            warning: "#fbc100",
          },
          dark: {
            primary: "#08090d",
          },
        },
      },
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
})
