import Vue from "vue"
import VueMoment from "vue-moment"
import moment from "moment"
import "moment/locale/es"
Vue.use(VueMoment, { moment })

export const mixDate = {
    methods: {
        formatoFecha: function(fecha) {
            const date1 = moment(fecha).format('D MMMM, YYYY')
            const date = moment(fecha)
    
            let dias = {
              0: 'Lun',
              1: 'Mar',
              2: 'Mie',
              3: 'Jue',
              4: 'Vie',
              5: 'Sab',
              6: 'Dom'
            }
            const dia = dias[moment(date).weekday()]
            return dia+' '+date1+' - '+date.format('h:mma')
        },

        hora(fecha){
            return moment(fecha).format('h:mma')
        },

        fromNow(date){
            return moment( date ).fromNow()
        },

        formatoSoloFecha(fecha){
            const date1 = moment(fecha).format('D MMMM, YYYY')
            const date = moment(fecha)
    
            let dias = {
              0: 'Lun',
              1: 'Mar',
              2: 'Mie',
              3: 'Jue',
              4: 'Vie',
              5: 'Sab',
              6: 'Dom'
            }
            const dia = dias[moment(date).weekday()]
            return dia+' '+date1
        },

        diference(date1, date2){
            //const fechaInicio = new Date('2016-07-12').getTime();
            const diff = new Date(date2).getTime() - new Date(date1).getTime()
            //return diff/(1000*60*60*24)
            const days = Math.floor(diff / (24*60*60*1000))
            const daysms = diff % (24*60*60*1000)
            const hours = Math.floor(daysms / (60*60*1000))
            const hoursms = diff % (60*60*1000)
            const minutes = Math.floor(hoursms / (60*1000))
            const minutesms = diff % (60*1000)
            const sec = Math.floor(minutesms / 1000)
            return days + "(d) " + hours + ":" + minutes + ":" + sec
        },

        formatBasic(fecha){
            return moment(fecha).format('YYYY-MM-DD HH:mm:ss')
        }
    },
}