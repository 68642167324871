<template>
    <div class="text-center">
      <v-menu
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-x
      >        
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                x-small
                fab
                v-bind="attrs"
                v-on="on"
            >
                <v-icon v-if="contract.state_contract=='active'">mdi-stop</v-icon>
                <v-icon v-else>mdi-play</v-icon>
            </v-btn>
        </template>        
        <v-card class="warning" dark>
            <v-card-text>
                <v-switch
                    dark
                    :color="color"
                    v-model="stateContract"
                    :label="stateContractText"
                    v-on:change="setChanges"
                ></v-switch>
            </v-card-text>
  
            <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                text
                rounded
                small
                @click="cancel"
            >
                Cancelar
            </v-btn>
            <v-btn
                color="primary"
                text
                small
                @click="submit"
                :loading="loading"
            >
                Guardar
            </v-btn>
            </v-card-actions>
        </v-card>
      </v-menu>
    </div>
  </template>


<script>
import { mapActions } from 'vuex'    

    export default {
        name: 'StateSwitcher',
        props: {
            contract: {
                type: Object,
            }
        },
        data() {
            return {
                menu: false,
                color: 'success',
                stateContract: false,
                stateContractText: 'Activo',
                loading: false
            }
        },

        methods: {
            ...mapActions(['updateContractState']),
            setChanges() {
                console.log('cambio')
                if(this.stateContract){                
                    this.stateContractText = 'Activo'
                    this.color= 'success'
                }else{
                    this.stateContractText = 'Inactivo'
                    this.color= 'red'
                }
            },
            setData(){
                if(this.contract.state_contract=='active'){
                this.stateContract = true
                this.stateContractText = 'Activo'
                this.color= 'success'
                }else{
                    this.stateContract = false
                    this.stateContractText = 'Inactivo'
                    this.color= 'red'
                }
            },
            cancel(){
                this.setData()
                this.menu = false
            },
            submit(){
                this.loading = true
                this.updateContractState({
                    id: this.contract.id,
                    state: this.stateContract
                }).then(resp=>{
                    this.loading = false
                    this.menu = false
                })
            }
        },

        created () {
            this.setData()
        },
        
    }
</script>

<style lang="scss" scoped>

</style>