<template>  
    <v-dialog
      v-model="dialog"
      persistent
      width="400"
    >
      <v-card>
        <v-card-title class="secondary white--text" v-if="user!=null">
          {{ user.name }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
              <v-row dense>
                <v-col cols="12">
                  <v-text-field
                    v-model="clave"
                    label="Password"
                    type="password"
                    autocomplete="off"
                    :rules="[
                      (v) => !!v || 'Password es un campo requerido',
                      (v) => v.length >= 6 || 'Minimo 6 caracteres',
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="repetir_clave"
                    label="Repetir Clave"
                    type="password"
                    autocomplete="off"
                    :rules="[
                      clave === repetir_clave || 'Passwords no coiciden',
                      (v) => v.length >= 6 || 'Minimo 6 caracteres',
                    ]"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            small
            class="darkpage--text"
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            rounder
            small
            color="success"
            class="darkpage--text"
            :loading="loading"
            :disabled="!valid"
            @click="setUser"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
  </template>
  
  <script>
  import { mapActions } from 'vuex'
    export default {
        name: 'ChangePassword',
        data() {
          return {
            clave: '',
            repetir_clave: '',
            loading: false,
            lazy: false,
            valid: false,
            dialog: false,
            user: null
          }
        },
        methods: {
          ...mapActions(['updateUserClave']),
          setUser() {
            if(this.valid && this.user!=null){
              this.loading = true
              const data = {
                password: this.clave,
                password_confirmation: this.clave,
                id: this.user.id
              }
              this.updateUserClave(data).then( ()=>{
                this.loading = false
                this.user = null
                this.$root.$emit('showMsg',{
                    mensaje: 'Password actualizado con Exito',
                    clase: 'success'
                })
                this.dialog = false
              })
            }
          },
          close(){
            this.clave = ''
            this.repetir_clave = ''
            this.dialog = false
          }
        },
        created () {
            this.$root.$on("verChangePassword", (user) => {
                this.user = user
                this.dialog = true
	        })
        },
    }
  </script>
  
  <style lang="scss" scoped>
  
  </style>