<template>
    <v-card height="400px">
        <v-toolbar dense>            
            <v-toolbar-title>Vehículos</v-toolbar-title>
            <FormMenuVehicle
                :editmode="true"
            />
        </v-toolbar>
        <v-card-text>

        </v-card-text>
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            #Placa
                        </th>
                        <th class="text-left">
                            Parqueo
                        </th>
                        <th class="text-left">
                            Lugar
                        </th>
                        <th class="text-left">
                            Tipo
                        </th>
                        <th class="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" vehi in vehicles" :key="vehi.place_id">
                        <td>{{ vehi.placa }}</td>
                        <td style="text-transform: uppercase;">{{ vehi.place.parking.title }}</td>
                        <td>{{ vehi.place.number }}</td>
                        <td>{{ vehi.type_vehi }}</td>
                        <td>
                            <v-btn 
                                x-small 
                                icon 
                                @click="confirm=true"
                                :disabled="confirm"
                            >
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                            <v-btn 
                                x-small
                                rounded
                                color="warning"
                                v-if="confirm"
                                @click="remove(vehi)"
                                :loading="loading"
                            >
                                Quitar
                            </v-btn>
                            <v-btn 
                                x-small
                                text
                                @click="confirm=false"
                                v-if="confirm"
                            >
                                Cancelar
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import FormMenuVehicle from '@/components/admin/prepagos/FormMenuVehicle'
import { mapActions } from 'vuex'

    export default {
        name: 'VehiclesTableEdit',
        data() {
            return {
                loading: false,
                confirm: false
            }
        },
        methods: {
            ...mapActions(['removeVehicle']),
            remove(vehi){
                this.loading = true
                this.removeVehicle({
                    id: vehi.id,
                    cid: vehi.contract_id,
                    pid: vehi.place_id,
                    pkid: vehi.place.parking_id
                }).then( ()=>{
                    this.confirm = false
                    this.$emit('updateVehicles', vehi.id)
                    this.loading = false
                })
            }
        },
        props: {
            vehicles: {
                type: Array
            }
        },
        components: {
            FormMenuVehicle
        },
    }
</script>

<style lang="scss" >

</style>