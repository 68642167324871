import Vue from 'vue'
import VueRouter from 'vue-router'
import profile from '../assets/profile.png';
import Admin from '@/views/admin/Admin'
import Prepagos from '@/views/admin/Prepagos'
import Promociones from '@/views/admin/Promociones'
import Tarifas from '@/views/admin/Tarifas'
import Users from '@/views/admin/Users'
import Ingresos from '@/views/admin/Ingresos'
import Profile from '@/views/admin/Profile'
import Login from '@/views/public/Login'
import store from '@/store'

Vue.use(VueRouter)
const routes = [
  {   
    path: '/',
    name: 'admin',
    component: Admin,
    meta:{requiresAuth: true }
  },
  { 
    path: '/prepagos',
    name: 'prepagos',
    component: Prepagos,
    meta:{ requiresAuth: true }
  },
  {
    path: '/promociones',
    name: 'promociones',
    component: Promociones,
    meta:{ requiresAuth: true }
  },
  {
    path: '/tarifas',
    name: 'tarifas',
    component: Tarifas,
    meta:{ requiresAuth: true }
  },
  {
    path: '/users',
    name: 'users',
    component: Users,
    meta:{ requiresAuth: true }
  },
  {
    path: '/ingresos',
    name: 'ingresos',
    component: Ingresos,
    meta:{ requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta:{ requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next()
  }
})

export default router
