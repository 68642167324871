var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',[_c('v-switch',{attrs:{"label":"Editar","color":"secondary"},model:{value:(_vm.editPassword),callback:function ($$v) {_vm.editPassword=$$v},expression:"editPassword"}})],1),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Nuevo Password","type":"password","autocomplete":"off","disabled":!_vm.editPassword,"rules":[
        (v) => !!v || 'Password es un campo requerido',
        (v) => v.length >= 6 || 'Minimo 6 caracteres',
      ],"outlined":"","dense":""},model:{value:(_vm.clave),callback:function ($$v) {_vm.clave=$$v},expression:"clave"}}),_c('v-text-field',{attrs:{"label":"Repetir Nuevo Password","type":"password","autocomplete":"off","disabled":!_vm.editPassword,"rules":[
        _vm.clave === _vm.repetir_clave || 'Passwords no coiciden',
        (v) => v.length >= 6 || 'Minimo 6 caracteres',
      ],"outlined":"","dense":""},model:{value:(_vm.repetir_clave),callback:function ($$v) {_vm.repetir_clave=$$v},expression:"repetir_clave"}})],1),(_vm.editPassword)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"rounded":"","x-small":""},on:{"click":function($event){_vm.editPassword = false}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"secondary",attrs:{"rounded":"","x-small":""}},[_vm._v("Cambiar")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }