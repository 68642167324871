<template>
<v-dialog
    v-model="dialog"
    persistent    
>
<template v-slot:activator="{ on, attrs }">
<v-btn
    v-bind="attrs"
    v-on="on"
    color="accent"
    small
    dark
    fab
>
    <v-icon>mdi-plus</v-icon>
</v-btn>
</template>
<v-card dark class="dark-page">
    <v-card-title class="secondary">Agregar Vehiculo</v-card-title>
    <v-card-text class="pt-2">
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-row no-gutters dense>
                <v-col cols="12" sm="3" md="3">
                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field
                                label="# Placa"                      
                                :rules="[(v) => !!v || 'Placa es requerida']"
                                outlined
                                filled
                                dense
                                v-model="models.placa"
                                @input="models.placa = models.placa.toUpperCase()"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-radio-group
                                v-model="models.type_vehi"
                                row
                                >
                                <v-radio
                                    label="Auto"
                                    value="auto"
                                ></v-radio>
                                <v-radio
                                    label="Moto"
                                    value="moto"
                                ></v-radio>
                            </v-radio-group>
                        </v-col>
                        <v-col cols="12" v-if="models.place!=null">
                            <v-card>
                                <v-card-title class="text-uppercase">{{ models.place.title }}</v-card-title>
                                <v-card-text>
                                    <div class="box-place" >
                                        {{models.place.number}}
                                        <v-btn 
                                            class="accent corner" 
                                            x-small
                                            fab
                                            @click="remove"
                                        >
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-col>
                <v-col cols="12" sm="9" md="9">
                    <PanelParqueos 
                        @addPlace="setPlace"
                    />
                </v-col>
            </v-row>
        </v-form>
    </v-card-text>
    <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="cancelar"> Cancel </v-btn>
        <v-btn
            v-if="editmode"
            @click="addToEdit"
            color="primary"
            rounded
            :disabled="!valid"
        > ok +</v-btn>
        <v-btn
            v-else
            @click="add"
            color="primary"
            rounded
            :disabled="!valid"
        > ok </v-btn>
    </v-card-actions>
</v-card>
<v-snackbar v-model="snackbar" :timeout="6000" bottom color="error">
    <p class="title white--text float-left">{{ mensaje }}</p>
    <v-btn
        small
        icon
        class="darkpage white--text float-right"
        fab
        @click="snackbar = false"
    >x</v-btn>
</v-snackbar>
</v-dialog> 
</template>

<script>
import PanelParqueos from '@/components/admin/prepagos/PanelParqueos'

export default {
    name: 'FormMenuVehicle',
    props: {
        editmode: {
            type: Boolean,
            default: false
        },
    },

    components: {
        PanelParqueos,
    },
    data() {
        return {
            dialog: false,
            valid: false,
            lazy: false,            
            models:{
                placa: '',
                type_vehi: 'auto',
                state_vehi: 'active',
                place: null,
            },
            snackbar: false,
            mensaje: ''
        }
    },
    methods: {
        add() {
            if(this.valid){
                if(this.models.place==null){
                    this.valid = false
                    this.mensaje = 'Parqueo sin asignar'
                    this.snackbar = true
                }else{
                    this.$root.$emit('vehiclesAdded', {
                        placa: this.models.placa,
                        title: this.models.place.title,
                        type_vehi: this.models.type_vehi,
                        place_id: this.models.place.id,
                        number: this.models.place.number
                    })
                    this.cancelar()
                }
            }
        },

        addToEdit(){
            if(this.valid){
                if(this.models.place==null){
                    this.valid = false
                    this.mensaje = 'Parqueo sin asignar'
                    this.snackbar = true
                }else{
                    this.$root.$emit('addVehiclesToList', {
                        placa: this.models.placa,
                        title: this.models.place.title,
                        type_vehi: this.models.type_vehi,
                        place_id: this.models.place.id,
                        number: this.models.place.number
                    })
                    this.cancelar()
                }
            }
        },

        setPlace(place){
            this.models.place = place
        },
        remove(){
            this.models.place = null
        },
        cancelar(){
            this.models.placa = ''
            this.type_vehi = 'auto'
            this.models.place = null
            this.dialog = false
        }
    },    

}
</script>

<style lang="scss">
    .corner{
        position: absolute;
        top: -10px;
        right: -10px;
    }
</style>