<template>
  <v-card>
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-title>
          <v-switch
            v-model="editNameUserName"
            label="Editar"
            color="accent"
          ></v-switch>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="name"
            label="Nombre Completo"
            :rules="requiredRule"
            :disabled="!editNameUserName"
            outlined
            dense
          ></v-text-field>
          <v-text-field
            v-model="email"
            label="UserName"
            :disabled="!editNameUserName"
            :rules="emailRules"
            :error-messages="errorEmail"
            autocomplete="off"
            outlined
            dense
          ></v-text-field>
        </v-card-text>
        <v-card-actions v-if="editNameUserName">
          <v-btn
            rounded
            class="primary white--text"
            x-small
            @click="editNameUserName = false"
            >Cancelar</v-btn
          >
          <v-btn 
            rounded 
            class="accent darkpage--text" 
            x-small
            :disabled="!valid"
            >Guardar</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
    </v-form>
  </v-card>
</template>
<script>
import { mapActions } from 'vuex';

export default {
  name: "FormUserData",
  data() {
    return {
        requiredRule: [(v) => !!v || "Este Campo es requerido"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
        loading: false,
        lazy: false,
        valid: false,
        editNameUserName: false,
        email: '',
        errorEmail: '',
        name: '',
        id: 0
    }    
  },

  methods: {
    ...mapActions(['checkEmail2']),
  },

  watch: {
    email(newValue, oldValue) {
      if(this.editNameUserName){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(newValue)){
            this.checkEmail2({
              email: newValue
            }).then(resp=>{
                this.errorEmail=""
                this.valid = true
            }).catch(err=>{
              if(err.status==422){
                this.errorEmail="Email ingresado ya existe"
                this.valid = false
              }
            })
        }
      }
    }
  },

  created () {
    this.editNameUserName = false
    this.$root.$on('setFormDataProfile', (data)=>{
      this.name = data.name
      this.email = data.email
      this.id = data.id
    })

  },
}
</script>

<style lang="scss" scoped>
</style>