<template>
  <v-card>
    <v-card-title>
      <v-switch
        v-model="editPassword"
        label="Editar"
        color="secondary"
      ></v-switch>
    </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="clave"
        label="Nuevo Password"
        type="password"
        autocomplete="off"
        :disabled="!editPassword"
        :rules="[
          (v) => !!v || 'Password es un campo requerido',
          (v) => v.length >= 6 || 'Minimo 6 caracteres',
        ]"
        outlined
        dense
      ></v-text-field>
      <v-text-field
        v-model="repetir_clave"
        label="Repetir Nuevo Password"
        type="password"
        autocomplete="off"
        :disabled="!editPassword"
        :rules="[
          clave === repetir_clave || 'Passwords no coiciden',
          (v) => v.length >= 6 || 'Minimo 6 caracteres',
        ]"
        outlined
        dense
      ></v-text-field>
    </v-card-text>
    <v-card-actions v-if="editPassword">
      <v-spacer></v-spacer>
      <v-btn rounded class="primary" x-small @click="editPassword = false"
        >Cancelar</v-btn
      >
      <v-btn rounded class="secondary" x-small>Cambiar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: "FormUserPassword",
  data() {
    return {
      loading: false,
      lazy: false,
      valid: false,
      clave: "",
      repetir_clave: "",
      editPassword: false,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>