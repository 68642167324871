<template>
<v-snackbar v-model="snackbar" :timeout="6000" top :color="clase">
    <p class="title black--text float-left">{{ mensaje }}</p>
    <v-btn
        x-small
        icon
        class="accent black--text float-right"
        @click="snackbar = false"
    >x</v-btn>
</v-snackbar>
</template>

<script>
    export default {
        name: 'PoputMsg',
        data() {
            return {
                clase: 'success',
                snackbar: false,
                mensaje: ''
            }
        },

        created() {
		    this.$root.$on("showMsg", (data) => {
		      this.clase = data.clase
		      this.mensaje = data.mensaje
		      this.snackbar = true
	    	})
	  	},

    }
</script>

<style lang="scss" scoped>

</style>