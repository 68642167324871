<template>
  <v-simple-table >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            <div>##</div>
          </th>
          <th class="text-left">
            <div>Nombre</div>
          </th>
          <th class="text-left">
            Username
          </th>
          <th class="text-left">
            Rol
          </th>
          <th class="text-left">
            Estado
          </th>
          <th class="text-center"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in users"
          :key="item.id"
        >
          <td> {{ index+1 }} </td>
          <td>{{ item.name }}</td>
          <td>{{ item.email }}</td>
          <td>
            <v-chip v-if="item.rol=='control'" small color="secondary">{{ item.rol }}</v-chip>
            <v-chip v-else small color="primary">{{ item.rol }}</v-chip>
          </td>
          <td>
            <div v-if="item.state=='inactive'" class="error--text">{{ item.state }}</div>
            <div v-else >{{ item.state }}</div>
          </td>
          <td class="text-center">
            <v-btn 
              x-small 
              fab
              @click="openChangePassword(item)"
            >
                <v-icon>mdi-key-variant</v-icon>
            </v-btn>
            <FormEditUser 
              :user="item"
            />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import FormEditUser from '@/components/admin/users/FormEditUser'

    export default {
        name: 'UsersTable',
        props: {
            users: {
                type: Array,
                default: []
            },
        },
        methods: {
          openChangePassword(user) {
            this.$root.$emit("verChangePassword", user)
          }
        },
        components: {
          FormEditUser
        },
    }
</script>

<style lang="scss" scoped>

</style>