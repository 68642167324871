<template>
  <v-simple-table >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            <div>##</div>
          </th>
          <th class="text-left">
            <div>No. Placa</div>
          </th>
          <th class="text-left">
            Tarifa
          </th>
          <th class="text-left">
            Costo
          </th>
          <th class="text-left">
            Estado
          </th>
          <th class="text-center">
              --
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in promociones"
          :key="item.id"
        >
          <td> {{ index+1 }} </td>
          <td style="text-transform: uppercase">{{ item.num_placa }}</td>
          <td>{{ item.tarifa.name_tarifa }}</td>
          <td>
            <v-chip small>{{ item.tarifa.costo }} bs</v-chip>            
          </td>
          <td>
            <div v-if="item.state_promo=='inactive'" class="error--text">{{ item.state_promo }}</div>
            <div v-else >{{ item.state_promo }}</div>
          </td>
          <td class="text-center">
            <!-- <MenuPromoEditState
              :promo="item"
            /> -->
            <FormEditPromo 
              :promo="item"
            />
            <DeleteMenu 
              :promo="item"
            />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
  import DeleteMenu from '@/components/admin/promos/DeleteMenu'
  //import MenuPromoEditState from '@/components/admin/promos/MenuPromoEditState'
  import FormEditPromo from '@/components/admin/promos/FormEditPromo'

    export default {
        name: 'PromoTable',
        components: {
          DeleteMenu,
          FormEditPromo
        },
        props: {
            promociones: {
                type: Array
            },
        }        
    }
</script>

<style lang="scss" scoped>

</style>