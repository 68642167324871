<template>
  <div>
      <AppBar 
        :drawer="drawer"
        @showHide="hideShow"
      ></AppBar>
      <SideMenu></SideMenu>
  </div>
</template>
<script>
import { mapGetters } from "vuex"
import AppBar from "@/components/admin/menu/AppBar"
import SideMenu from "@/components/admin/menu/SideMenu"

export default {  
  name: "MenuNavBar",

  data() {
    return {
      drawer: null,
    }
  },

  components: {
    AppBar,
    SideMenu
  },

  methods: {

    cambiar() {
      this.drawer = !this.drawer
    },

    hideShow(){
      this.drawer = !this.drawer
      this.$root.$emit("showHide")
    }

  },

  computed: {
    ...mapGetters(["authUser"])
  },

  created() {
    this.$root.$on('cambiarNombre', (data) =>{        

    })

  },
}
</script>

<style scoped>
  .tile {
    margin: 9px;
    border-radius: 9px;
  }

  .tile {
    background: #494961;
  }

  .v-list-item--active {
    background-color: #39dcef78;
  }

  i.v-icon.v-icon {
    color: #39dcef;
  }
</style>