<template>  
    <v-dialog
      v-model="dialog"
      persistent
      width="400"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="accent"
          dark
          fab
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="secondary white--text">
          <span class="text-h5">Nuevo Cliente con Promoción</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-col cols="12">
                  <v-text-field
                    v-model="placa"
                    label="No Placa"
                    :rules="[(v) => !!v || 'Placa es requerida']"
                    @input="placa = placa.toUpperCase()"
                    outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-select
                      v-model="tarifa"
                      :items="tarifas"
                      :rules="[(v) => !!v || 'Placa es requerida']"
                      label="Tarifa"
                      item-text="name_tarifa"
                      return-object
                      outlined
                  ></v-select>
                </v-col>
              </v-form>
            </v-row>
          </v-container>
        </v-card-text>
       
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="darkpage--text"
            color="grey-4"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            class="darkpage--text"
            rounder
            @click="submit"
            :loading="loading"
            :disabled="!valid"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
</template>

<script>
import { mapActions } from 'vuex'

    export default {
        name: 'FormNewPromo',
        props: {
          tarifas: {
            type: Array,
          },
        },
        data() {
          return {
            dialog: false,
            valid: false,
            lazy: false,
            tarifa: null,
            placa: '',
            loading: false,
            disabled: false
          }
        },
        methods: {
          ...mapActions(['createPromo']),
          submit() {
            if(this.valid){
              const fdata = {
                num_placa: this.placa,
                tarifa_id: this.tarifa.id,
                name_usuario: '',
                tarifa: this.tarifa
              }

              this.loading = true
              this.createPromo(fdata).then( ()=>{
                this.placa = ''
                this.tarifa = null
                this.loading = false
                this.$refs.form.resetValidation()
                this.$emit('promoAdded')
                this.dialog = false
              })
            }
          }
        },
    }
</script>

<style lang="scss" scoped>

</style>