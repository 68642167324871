import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"
import router from '../router'

Vue.use(Vuex)

//axios.defaults.baseURL = "http://localhost/api-parking/public/api"
axios.defaults.baseURL = "https://parqueosbazan.com/service-parkings/public/api"

export default new Vuex.Store({
  state: {
    status: "",
    token: localStorage.getItem("token") || "",
    user: localStorage.getItem("user") || {},
    pictures: [],
    tarifas: [],
    config: null,
    contracts: [],
    parqueos:[],
    promotions:[],
    promos:[],
    users:[],
    ticketsHoy: [],
    ingresos: null
  },

  getters: {
    isLoggedIn: (state) => !!state.token,
    authUser: (state) => state.user,
    pictures: (state)=> state.pictures,
    tarifas: (state)=> state.tarifas,
    parqueos: (state)=> state.parqueos,
    contracts: (state)=> state.contracts,
    promotions: (state)=> state.promotions,
    users: (state)=> state.users,
    config: (state)=> state.config,
    ingresos: (state)=> state.ingresos,
    ticketsHoy: (state)=> state.ticketsHoy,
    tarifasPromo: (state)=> state.tarifas.filter(tarifa=>tarifa.name_tarifa!='Normal')
  },

  mutations: {
    logout(state) {
      state.status = ""
      state.token = ""
      state.user = {}
    },

    SET_TOKEN(state, token) {
      state.status = "success"
      state.token = token
    },

    SET_USER(state, user) {
      state.user = user
    },

    SET_AUTH_ERROR(state, error) {
      state.status = error
    },

    expires(state) {
      state.status = "token timeout"
    },
    
    AUTH_SUCCESS(state, token) {
      state.status = "success"
      state.token = token
    },

    SET_TARIFAS(state, payload) {
      state.tarifas = payload.tarifas
      state.config = payload.config
      state.ticketsHoy = payload.tickets
    },

    SET_INGRESOS(state, payload){
      state.ingresos = payload
    },

    RESET_INGRESOS(state){
      state.ingresos = null
    },

    SET_CONTRACTS(state, payload) {
      state.contracts = payload.contracts
      state.tarifas = payload.tarifas
      state.parqueos = payload.parqueos
    },

    UPDATE_CONTRACT_STATE(state, payload){
      let contract = state.contracts.find(contract=>contract.id==payload.id)
      if(contract!=undefined){
        if(payload.state){
          contract.state_contract = 'active'
        }else{
          contract.state_contract = 'inactive'
        }
      }
    },

    UPDATE_CONTRACT(state, payload){
      let contract = state.contracts.find(contract=>contract.id==payload.id)
      if(contract!=undefined){
        contract.client.name = payload.name
        contract.client.ci = payload.ci
        contract.client.mobile = payload.mobile
        contract.client.email = payload.email
        contract.start = payload.start
        contract.end = payload.end
        contract.turno = payload.turno
        contract.chip = payload.chip
        contract.monto = payload.monto
        //contract.state_contract = payload.state_contract
      }
    },

    SET_PROMOS(state, payload) {
      state.promotions = payload
      state.promos = [...state.promotions]
    },

    UPDATE_PROMOS(state, payload){
      state.promotions.map(elem=>{
        if(elem.id==payload.id){
          if(payload.state){
            elem.state_promo = 'active'
          }else{
            elem.state_promo = 'inactive'
          }
        }
      })
    },

    UPGRATE_TARIFAS_PROMOS(state, payload){
      state.tarifas.map(tarifa => {
        if(tarifa.id==payload.id){
          if(payload.state=='active'){
            tarifa.state_tarifa = 'inactive'
          }else{
            tarifa.state_tarifa = 'active'
          }
        }
      })
    },

    UPDATE_TARIFA(state, payload){
      state.tarifas.map(element=>{
        if(element.id===payload.id){
          element.name_tarifa = payload.name_tarifa
          element.costo = payload.costo
          element.type_tarifa = payload.type_tarifa
          element.state_tarifa = payload.state_tarifa
          }
      })
    },

    SET_USERS(state, payload){
      state.users = payload
    },

    UPDATE_USER(state, payload){
      state.users.map(user=>{
        if(user.id==payload.id){
          user.name = payload.name
          user.email = payload.email
          user.rol = payload.rol
          user.state = payload.state
      }})
    },

    CREATE_USER(state, payload){
      state.users.push(payload)
    },

    CREATE_CONTRACT(state, payload){
      const contract = {
        id: payload.id,
        start: payload.start,
        end: payload.end,
        turno: payload.turno,
        chip: payload.chip,
        monto: payload.monto,
        tipo: 'mensual',
        state_contract: 'active',
        client_id: payload.clid,
        client: {
          id: payload.clid,
          name: payload.name,
          mobile: payload.mobile,
          ci: payload.ci,
          email: payload.email,
          state_client: 'active',
        },
        vehicles: payload.vehicles
      }
      state.contracts.push(contract)
      payload.vehicles.forEach(vehi => {
        let parqueo = state.parqueos.find(parqueo=> parqueo.id == vehi.place.parking.id)
        if(parqueo!=undefined){
          parqueo.places.map(place=>{
            if(place.id==vehi.place_id){
              place.state_place = 'contract'
            }
          })
        }else{
          console.log('CREATE_CONTRACT,parqueo not found', parqueo)
          }
      })
    },

    FILTER_PROMOS(state, payload){
      if(payload==0){
        state.promotions = state.promos
      }else{
        state.promotions = state.promos.filter(promo=>promo.tarifa_id==payload )
      }
    },

    UPDATE_CONFIG(state, payload){
      state.config.nombrenegocio = payload.nombre_negocio
      state.config.direccion = payload.direccion
      state.config.telefono = payload.telefono
      state.config.adicional = payload.adicional
    },

    UPDATE_CONFIG_PSETUP(state, payload){
      state.config.printsetup = payload.printsetup
    },

    CREATE_TARIFA(state, payload){
      state.tarifas.push(payload)
    },

    CREATE_PROMO(state, payload){
      state.promotions.push(payload)
    },

    UPGRATE_PROMOS(state, payload){
      state.promotions = state.promotions.filter(promo=>promo.id!=payload)
      state.promos = [...state.promotions]
    },
    UPDATE_PROMO(state, payload){
      let promoRow = state.promotions.find(elem=> elem.id==payload.id)
      if(promoRow!=undefined){
        promoRow.num_placa=payload.num_placa 
        promoRow.tarifa_id=payload.tarifa_id 
        payload.state?  promoRow.state_promo='active' : promoRow.state_promo='inactive'
        let tarifa = state.tarifas.find(tar => tar.id == payload.tarifa_id)
        if(tarifa!=undefined){
          promoRow.tarifa=tarifa
        }
      }
    }

  },
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        axios({ url: "/login2", data: user, method: "POST" })
          .then((resp) => {
            const token = resp.data.token
            
              localStorage.setItem("token", token)
              axios.defaults.headers.common["Authorization"] = token
              commit("SET_TOKEN", token)
              resolve(resp)
          })
          .catch((err) => {
            commit("SET_AUTH_ERROR")
            localStorage.removeItem("token")
            reject(err)
          })
      })
    },    

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout")
        localStorage.removeItem("token")
        localStorage.removeItem("user")
        delete axios.defaults.headers.common["Authorization"]
        resolve()
      })
    },

    refesh_token(context) {
      context.commit("expires")
      axios({ url: "/refresh", method: "POST" })
        .then((resp) => {
          const token = resp.data.token
          localStorage.setItem("token", token)
          axios.defaults.headers.common["Authorization"] = token
          context.commit("AUTH_SUCCESS", token)
        })
        .catch((err) => {
          console.log(err)
        })
    },

    retrieveTarifas(context){
      return new Promise( (resolve, reject)=>{
         axios.defaults.headers.common["Authorization"]="Bearer "+context.state.token
         axios({
          url: "/tarifas",
          method: "GET"
         }).then((response)=>{
          context.commit("SET_TARIFAS", response.data)
          resolve()
        }).catch(err=> {
          if(err.response.status==401){
            router.replace({ path: '/login' })
          }
        })
      })
    },

    retrievePromos(context){
      return new Promise( (resolve, reject)=>{
          axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
          axios({
          url: "promotions",
          method: "GET"
        }).then((response)=>{
          context.commit("SET_PROMOS", response.data)
          resolve()
        }).catch(err=> {
          if(err.response.status==401){
            router.replace({ path: 'login' })   }
        })
      })
    },

    retrievePrePagos(context){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
        axios({
        url: "contracts",
        method: "GET"
        }).then((response)=>{
          context.commit("SET_CONTRACTS", 
          response.data)
          resolve()
        }).catch(err=> {
          if(err.response.status==401){
            router.replace({ path: 'login' })
          }
        })
      })
    },

    createTarifa(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
        axios({ url: "/tarifas", data: data, method: "POST" }).then((
          response) => { 
            data.id = response.data
            data.state_tarifa = 'active'
            data.gestion = new Date().getFullYear()
            context.commit("CREATE_TARIFA", data)
            resolve('ok')
            }).catch((err) => {
              console.error('createTarifa',err)})
            })
    },

    createContract(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
        axios({ url: "/contracts", data: data, method: "POST" })
        .then((response) => {
          data.id = response.data.cid
          data.clid = response.data.clid
          data.vehicles = response.data.vehicles
          context.commit("CREATE_CONTRACT", data)
          resolve('ok')}).catch((err) => {
            console.error('createContract',err)})
      })
    },

    createPromo(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"]="Bearer "+context.state.token
        let fdata = {...data}
        delete fdata.tarifa
        axios({
          url: "/promotions", 
          data: fdata, method: "POST" 
        }).then((response) => {
          data.id = response.data
          data.state_promo = 'active'
          context.commit("CREATE_PROMO", data)
          resolve('ok')
        }).catch((err) => {
          console.error('CREATE_PROMO',err)})
        })
    },

    updatePromo(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"]="Bearer "+context.state.token
        let fdata = {...data}
        delete fdata.tarifa
        axios({
          url: "/promotions/"+data.id, 
          data: fdata, method: "PUT" 
        }).then((response) => {
          context.commit("UPDATE_PROMO", data)
          resolve('ok')
        }).catch((err) => {
          console.error('UPDATE_PROMO',err)})
        })
    },

    updateTarifa(context, data){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"] = "Bearer "+context.state.token
        axios({
          url: "tarifas/"+data.id,
          data: data,
          method: "PUT"
        }).then( response =>{
          context.commit("UPDATE_TARIFA", data)
          resolve('ok')
        }).catch( err =>{
          console.error('updateTarifa', err)
        })
      })
    },

    filtrarPromotions(context, tarifa){
      context.commit("FILTER_PROMOS", tarifa)
    },

    changeTarifa(context, data){
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"]="Bearer "+context.state.token
        axios({ 
          url: "tarifas/change/"+data.id,
          method: "GET" 
        }).then((response) =>{
          context.commit("UPGRATE_TARIFAS_PROMOS", data)
          resolve('ok')
        }).catch((err) => { console.error('UPGRATE_TARIFAS_PROMOS',err) })
       })
    },

    deletePromo(context, data){
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
        axios({
          url: "/promotions/"+data.id, 
          method: "DELETE" 
        }).then((response) => {
          context.commit("UPGRATE_PROMOS", data.id)
          resolve('ok')
        }).catch((err) => {
          console.error('UPGRATE_PROMOS',err)
        })
      })
    },

    retrieveUsers(context){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
        axios({ 
          url: "users/my-users", 
          method: "POST", 
          data: { token: context.state.token }}).then((response)=>{
            context.commit("SET_USERS", response.data)
            resolve()
            }).catch(err=> {
              if(err.response.status==401){
                router.replace({ path: '/login' })
              }
            })
          })
    },

    updateUser(context, data){
          return new Promise( (resolve, reject)=>{
            axios.defaults.headers.common["Authorization"] = "Bearer "+context.state.token
            axios({
              url: "users/"+data.id,
              data: data,
              method: "PUT"
            }).then( response =>{
              context.commit("UPDATE_USER", data)
              resolve('ok')
            }).catch( err =>{ console.error('updateUser', err)
          })
        })
    },
    
    updateUserClave(context, data){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"] = "Bearer "+context.state.token
        axios({
          url: "users/change/"+data.id,
          data: data,
          method: "POST"
        }).then( response =>{
          resolve('ok')
        }).catch( err =>{
          console.error('updateUserClave', err)
        })
      })
    },

    removeVehicle(context, data){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"] = "Bearer "+context.state.token
        axios({
          url: "vehicles/"+data.id,
          method: "DELETE"
        }).then( response =>{
          context.commit("UPDATE_VEHICLES", data)
          resolve('ok')
        }).catch( err =>{
          console.error('removeVehicle', err)
        })
      })
    },

    addVehicleToContract(context, data){
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
        axios({ url: "/vehicles", data: data, method: "POST"
      }).then((response) => {
        data.id = response.data.id
        data.state_vehi = 'active'
        data.place = response.data.place
        context.commit("ADD_VEHI_CONTRACT", data)
        resolve(data)
      }).catch((err) => {
        console.error('addVehicleToContract',err)
      })
      })
    },

    updateConfig(context, data){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"] = "Bearer "+context.state.token
        axios({
          url: "configs/1",
          data: data,
          method: "PUT"
        }).then( response =>{
          context.commit("UPDATE_CONFIG", data)
          resolve('ok')
        }).catch( err =>{console.error('updateConfig', err) })
      })
    },

    updatePrintSetup(context, data){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"] = "Bearer "+context.state.token
        axios({
          url: "configs/upgrate/1",
          data: data,
          method: "POST"
        }).then( response =>{
          context.commit("UPDATE_CONFIG_PSETUP", data)
          resolve('ok')
        }).catch( err =>{console.error('updatePrintSetup', err) })
      })
    },

    updateContract(context, data){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"] = "Bearer "+context.state.token
        axios({
          url: "contracts/"+data.id,
          data: data,
          method: "PUT"
        }).then( response =>{
          context.commit("UPDATE_CONTRACT", data)
          resolve('ok')
        }).catch( err =>{
          console.error('updateContract', err)
        })
      })
    },

    updateContractState(context, data){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"] = "Bearer "+context.state.token
        axios({
          url: "contracts/state/"+data.id,
          data: data,
          method: "POST"
        }).then( response =>{
          context.commit("UPDATE_CONTRACT_STATE", data)
          resolve('ok')
        }).catch( err =>{
          console.error('updateContractState', err)
        })
      })
    },

    retrieveIngresos(context, data){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
        context.commit("RESET_INGRESOS")
        axios({
          url: "parkings/ingresos",
          method: "POST", data
        }).then((response)=>{
          context.commit("SET_INGRESOS", response.data.outs.data)
          resolve({
            pagination:{
              current_page: response.data.outs.current_page,
              first_page: response.data.outs.first_page,
              from: response.data.outs.from,
              last_page: response.data.outs.last_page,
              next_page_url: response.data.outs.next_page_url,
              per_page: response.data.outs.per_page,
              prev_page_url: response.data.outs.prev_page_url,
              to: response.data.outs.to,
              total: response.data.outs.total
            },
            total: response.data.total,
            operators: response.data.users
          })
        }).catch(err=> {
          if(err.response.status==401){
            router.replace({ path: '/login' })
        }})
      })
    },

    createUser(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
        axios({ url: "users", 
        data: data, 
        method: "POST" 
      }).then((response) => {
        context.commit("CREATE_USER", 
          { 
            id: response.data,
            name: data.name,
            email: data.email,
            rol: data.rol,
            state: 'active',
            email_verified_at: null
          })
          resolve('ok')
      }).catch((err) =>{ console.error('createUser',err) })
      })
    },
    
    saveStatePromo(context, data) {
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
        axios({ 
          url: "promotions/change/"+data.id, data: data, method: "POST" 
        }).then((response) => {
          context.commit("UPDATE_PROMOS", data)
          resolve('ok')
        }).catch((err) => { console.error('saveStatePromo',err)})
      })
    },
    
    downloadPDF(context, data){
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
        //axios({ url: \"/parkings/report/pdf\", data: data, method: \"POST\" })
        axios({
          url: '/parkings/report/pdf',
          data: data,
          method: 'POST',
          responseType: 'blob', // important
        }).then((response) => {
          resolve(response.data)
        }).catch((err) => { console.error('downloadPDF',err) }) 
      })
    },

    retrieveActiveUser(context, data){
      return new Promise((resolve, reject)=>{
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token
        axios({
          url: "users/data/user",
          method: "GET"
          }).then((response)=>{            
            resolve(response.data)
          }).catch(err=> {
            if(err.response.status==401){
              router.replace({ path: 'login' })
            }
          })
      })
    },

    checkEmail2(context, data){
      return new Promise( (resolve, reject)=>{
        axios.defaults.headers.common["Authorization"] = "Bearer "+context.state.token
        axios({
          url: "users/check/email",
          data: data,
          method: "POST"
        }).then( response =>{
          resolve('ok')
        }).catch( err =>{
          reject(err.response)
        })
      })
    },

    downloadEXCEL(context, data){
      return new Promise((resolve, reject) => {
        axios.defaults.headers.common["Authorization"]="Bearer " + context.state.token        
        axios({
          url: '/parkings/report/excel',
          data: data,
          method: 'POST',
          responseType: 'blob',
        }).then((response) => {
          resolve(response.data)
        }).catch((err) => { console.error('downloadEXCEL',err) }) 
      })
    },
  },
  modules: {},
})
