<template>  
  <v-dialog
    v-model="dialog"
    persistent
    width="400"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn 
        x-small
        fab
        v-bind="attrs"
        v-on="on"
      >
          <v-icon>mdi-pencil</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="secondary white--text">
        <span class="text-h5">Nuevo Usuario</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  label="Nombre Completo"
                  :rules="requiredRule"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  label="UserName"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="6" md="6" sm="6">
                <v-radio-group
                  v-model="rol"
                  dense
                >
                  <v-radio
                    label="Control"
                    value="control"
                  ></v-radio>
                  <v-radio
                    label="Administrador"
                    value="admin"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="6" md="6" sm="6" class="d-flex justify-end">
                <v-switch
                  v-model="state"
                  :label="stateText"
                  v-on:change="changeText"
                  color="secondary"
                ></v-switch>
               </v-col>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="darkpage--text"
          text
          @click="dialog = false"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="success"
          class="darkpage--text"
          rounder
          :loading="loading"
          :disabled="!valid"
          @click="setUser"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>  
</template>
<script>
import { mapActions } from 'vuex'
  export default {
      name: 'FormEditUser',
      props: {
        user: {
          type: Object
        },
      },
      data() {
        return {
          dialog: false,
          name: '',
          email: '',
          rol: 'control',
          requiredRule: [(v) => !!v || "Este Campo es requerido"],
          loading: false,
          lazy: false,
          valid: false,
          state: false,
          stateText: 'Activo'
        }
      },
      methods: {
        ...mapActions(['updateUser']),
        setUser() {
          if(this.valid){
            this.loading = true

            let estado = 'inactive'
            if(this.state){ estado = 'active' }

            const data = {
              name: this.name,
              email: this.email,
              rol: this.rol,
              state: estado,
              id: this.user.id
            }
            this.updateUser(data).then( ()=>{
              this.loading = false
              this.$root.$emit('showMsg',{
                mensaje: 'Usuario actualizado con Exito',
                clase: 'success'
              })
              this.dialog = false
            })
          }
        },
        changeText(){
          if(this.state){
              this.stateText = 'Activo'
          }else{
              this.stateText = 'Inactivo'
          }
        }
      },
      created () {
        this.name = this.user.name
        this.email = this.user.email
        this.rol = this.user.rol
        if(this.user.state=='active'){
          this.state = true
          this.stateText = 'Activo'
        }else{
          this.state = false
          this.stateText = 'Inactivo'
        }
      },
  }
</script>

<style lang="scss" scoped>

</style>