<template>
  <v-simple-table >
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            <div>##</div>
          </th>
          <th class="text-left">
            <div>Tarifa</div>
          </th>
          <th class="text-left">
            Gestión
          </th>
          <th class="text-left">
            Costo
          </th>
          <th class="text-left">
            Tipo
          </th>
          <th class="text-left">
            Estado
          </th>
          <th class="text-center">
              --
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) in tarifas"
          :key="item.id"
        >
          <td> {{ index+1 }} </td>
          <td>{{ item.name_tarifa }}</td>
          <td>{{ item.gestion }}</td>
          <td>{{ item.costo }}</td>
          <td>
            <v-chip v-if="item.type_tarifa=='auto'" small color="secondary">{{ item.type_tarifa }}</v-chip>
            <v-chip v-else small color="primary">{{ item.type_tarifa }}</v-chip>
          </td>
          <td>
            <div v-if="item.state_tarifa=='inactive'" class="error--text">{{ item.state_tarifa }}</div>
            <div v-else >{{ item.state_tarifa }}</div>
          </td>
          <td class="text-center">
            <FormEditTarifa 
              :tarifa="item" />

              <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                      <v-btn
                          x-small
                          fab
                          v-bind="attrs"
                          v-on="on"
                          @click="showConfirm(item)"
                      >
                          <v-icon v-if="item.state_tarifa=='active'">mdi-stop</v-icon>
                          <v-icon v-else>mdi-play</v-icon>
                      </v-btn>
                  </template>
                  <span v-if="item.state_tarifa=='active'">¿Desactivar Tarifa y Promoción?</span>
                  <span v-else>¿Activar Tarifa y Promoción?</span>
              </v-tooltip>
          </td>
        </tr>
      </tbody>
      <ConfirmDialog v-if="tarifaElegida!=null"
        :dialog="showConfirmDialog"
        :title="setTitle()"
        :actual="{
          id: tarifaElegida.id,
          state: tarifaElegida.state_tarifa
        }"
        @closeConfirm="cerrarConfirmDialog"
      />
    </template>
  </v-simple-table>
</template>

<script>
import FormEditTarifa from '@/components/admin/tarifas/FormEditTarifa'
import ConfirmDialog from '@/components/admin/ConfirmDialog'

    export default {
        name: 'TarifaTable',
        data() {
          return {
            tarifaElegida: null,
            showConfirmDialog: false
          }
        },
        props: {
            tarifas: {
                type: Array,
                default: []
            },
        },
        components: {
          FormEditTarifa,
          ConfirmDialog
        },
        methods: {
          showConfirm(tarifa) {
            this.tarifaElegida = tarifa
            this.showConfirmDialog = true
          },
          cerrarConfirmDialog(){
            this.showConfirmDialog = false
            this.tarifaElegida = null
          },
          setTitle() {
            let text = 'Desactivar'
            if(this.tarifaElegida.state_tarifa=='inactive'){
              text = 'Activar'
            }
            return `¿Desea ${text} la Tarifa: '${ this.tarifaElegida.name_tarifa }'?, Promociones asociadas tambien serán afectadas`
          }
        },
    }
</script>

<style lang="scss" scoped>

</style>