var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"accent","dark":"","fab":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"secondary white--text"},[_c('span',{staticClass:"text-h5"},[_vm._v("Nuevo Usuario")])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":_vm.lazy},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nombre Completo","rules":_vm.requiredRule,"outlined":"","dense":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"UserName","rule":_vm.requiredRule,"outlined":"","dense":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Password","type":"password","autocomplete":"off","rules":[
                  (v) => !!v || 'Password es un campo requerido',
                  (v) => v.length >= 6 || 'Minimo 6 caracteres',
                ],"outlined":"","dense":""},model:{value:(_vm.clave),callback:function ($$v) {_vm.clave=$$v},expression:"clave"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Repetir Clave","type":"password","autocomplete":"off","rules":[
                  _vm.clave === _vm.repetir_clave || 'Passwords no coiciden',
                  (v) => v.length >= 6 || 'Minimo 6 caracteres',
                ],"outlined":"","dense":""},model:{value:(_vm.repetir_clave),callback:function ($$v) {_vm.repetir_clave=$$v},expression:"repetir_clave"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"row":"","dense":""},model:{value:(_vm.rol),callback:function ($$v) {_vm.rol=$$v},expression:"rol"}},[_c('v-radio',{attrs:{"label":"Operador","value":"control"}}),_c('v-radio',{attrs:{"label":"Admin","value":"admin"}})],1)],1),_c('v-col',{attrs:{"cols":"8"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"darkpage--text",attrs:{"text":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"darkpage--text",attrs:{"color":"success","loading":_vm.loading,"rounder":"","small":""},on:{"click":_vm.addUser}},[_vm._v(" Guardar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }