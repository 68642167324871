<template>
    <v-card height="400px">
        <v-toolbar dense>            
            <v-toolbar-title>Vehículos</v-toolbar-title>
            <FormMenuVehicle/>
        </v-toolbar>
        <v-card-text>

        </v-card-text>
        <v-card-text>
            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                        <th class="text-left">
                            #Placa
                        </th>
                        <th class="text-left">
                            Parqueo
                        </th>
                        <th class="text-left">
                            Lugar
                        </th>
                        <th class="text-left">
                            Tipo
                        </th>
                        <th class="text-center"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for=" (vehi, index) in vehicles" :key="vehi.place_id">
                        <td>{{ vehi.placa }}</td>
                        <td>{{ vehi.title }}</td>
                        <td>{{ vehi.number }}</td>
                        <td>{{ vehi.type_vehi }}</td>
                        <td>
                            <v-btn x-small icon @click="remove(index)">
                                <v-icon small>mdi-delete</v-icon>
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
import FormMenuVehicle from '@/components/admin/prepagos/FormMenuVehicle'
    export default {
        name: 'VahiclesTable',
        methods: {
            verMsg(mensaje) {
                this.mensaje = mensaje
                this.snackbar = true
            },
            remove(id){
                this.$root.$emit('vehiclesRemoved', id)
            }
        },
        props: {
            vehicles: {
                type: Array
            }
        },
        components: {
            FormMenuVehicle
        },
    }
</script>

<style lang="scss" >

</style>