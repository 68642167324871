
export const mixParking = {

    methods: {
        disableTarifa: function(data) {
            this.$store.dispatch("changeTarifa", data ).then( ()=>{
                this.$root.$emit('showMsg',{
                    mensaje: 'Tarifa y Promociones actualizadas con Exito',
                    clase: 'success'
                })
            })
        },
        deletePromo: function(promo){
            this.$store.dispatch("deletePromo", promo )
        }
    },
}