var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"persistent":"","width":"400"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.user!=null)?_c('v-card-title',{staticClass:"secondary white--text"},[_vm._v(" "+_vm._s(_vm.user.name)+" ")]):_vm._e(),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":_vm.lazy},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Password","type":"password","autocomplete":"off","rules":[
                  (v) => !!v || 'Password es un campo requerido',
                  (v) => v.length >= 6 || 'Minimo 6 caracteres',
                ],"outlined":"","dense":""},model:{value:(_vm.clave),callback:function ($$v) {_vm.clave=$$v},expression:"clave"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Repetir Clave","type":"password","autocomplete":"off","rules":[
                  _vm.clave === _vm.repetir_clave || 'Passwords no coiciden',
                  (v) => v.length >= 6 || 'Minimo 6 caracteres',
                ],"outlined":"","dense":""},model:{value:(_vm.repetir_clave),callback:function ($$v) {_vm.repetir_clave=$$v},expression:"repetir_clave"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"darkpage--text",attrs:{"text":"","small":""},on:{"click":_vm.close}},[_vm._v(" Cancelar ")]),_c('v-btn',{staticClass:"darkpage--text",attrs:{"rounder":"","small":"","color":"success","loading":_vm.loading,"disabled":!_vm.valid},on:{"click":_vm.setUser}},[_vm._v(" Guardar ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }