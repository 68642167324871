<template>
    <v-app>
      <MenuNavBar v-if="loggedIn"></MenuNavBar>
      <v-main class="gradient">
        <router-view />
      </v-main>
    </v-app>
</template>

<script>

/* import { mapGetters } from 'vuex' */
import MenuNavBar from '@/components/admin/MenuNavBar'

export default {
  name: "App",
  components: {
    MenuNavBar
  },
  computed: {
    /* ...mapGetters(["isLoggedIn"]), */
    loggedIn() {
      return this.$store.getters.isLoggedIn
    }
  }

}
</script>
<style>
  .gradient{
  background: #BBD2C5;  /* fallback for old browsers */
  background: -webkit-linear-gradient(45deg, #536976, #BBD2C5);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(45deg, #536976, #BBD2C5); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }

</style>