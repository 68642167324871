<template>
    <v-container>
        <v-card color="pa-4">
            <v-card-title>Registro pre-pago</v-card-title>
            <v-card-text>
                <v-row class="d-flex aling-center">
                    <v-col class="d-flex justify-start" cols="3">
                        <v-select
                            v-model="currentMonth"
                            :items="months"
                            dense
                            label="Mes"
                            outlined
                        ></v-select>
                    </v-col>
                    <v-col class="d-flex justify-end\">
                        <FormNewPrePago/>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text>
                <PrePagosList :contracts="contracts"/>
            </v-card-text>
        </v-card>
        <FormEditPrePago />
    </v-container>
</template>
<script>
    import PrePagosList from '@/components/admin/prepagos/PrePagosList'
    import FormNewPrePago from '@/components/admin/prepagos/FormNewPrePago'
    import FormEditPrePago from '@/components/admin/prepagos/FormEditPrePago'
    import { mapActions, mapGetters } from 'vuex'
    
    export default {
        name: "PrePagos",
        data() {
            return {
                months: ['Enero','Febrero', 'Marzo', 'Abril', 'Mayo',
                'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre',
                'Noviembre', 'Diciembre']
             }
        },
        components: {
            PrePagosList,
            FormNewPrePago,
            FormEditPrePago
        },
        methods: {
            ...mapActions(['retrievePrePagos'])
        },
        computed: {
            ...mapGetters(['contracts'])
        },
        created () {
            this.currentMonth = this.months[new Date().getMonth()]
            this.retrievePrePagos()
        },
    }
</script>
<style lang="scss" scoped>
</style>