<template>
<v-expansion-panels focusable>
  <v-expansion-panel hide-actions v-for="(contract, index) in contracts" :key="contract.id">
    <v-expansion-panel-header disable-icon-rotate>
      <v-row>
        <v-col cols="1" sm="1" md="1" >
          <div class="caption">##</div>
          <div>{{ index +1 }}</div>
        </v-col>
        <v-col cols="3" sm="3" md="3">
          <div class="centro">
            {{ contract.client.name }}
          </div>
          <div class="caption">
            {{ contract.client.ci }}
          </div>
        </v-col>
        <v-col cols="2" sm="2" md="2" >
          <div class="caption">Turno</div>
          <div>{{ contract.turno }}</div>
        </v-col>
        <v-col cols="2" sm="2" md="2" >
          <div class="caption">Monto(bs)</div>
          <v-chip small color="accent">{{ contract.monto }}</v-chip>
        </v-col>
        <v-col cols="2" sm="2" md="2" >
          <div class="caption">Finaliza</div>
          <div class="subtitle">{{ contract.end | moment("from", "now") }}</div>
        </v-col>
        <v-col cols="1" sm="1" md="1" class="dot">
          <div class="caption">&nbsp;</div>
            <svg v-if="contract.state_contract=='active'"
              viewBox="0 0 120 120" version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <circle fill="#7ac043" cx="23" cy="23" r="23"/>
            </svg>
            <svg v-else
              viewBox="0 0 120 120" version="1.1"
              xmlns="http://www.w3.org/2000/svg">
              <circle fill="#f04647" cx="23" cy="23" r="23"/>
            </svg>
        </v-col>
      </v-row>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="primary">
      <v-row class="pa-4">
        <v-col class="d-flex justify-start white--text">
          Inicio:&nbsp;{{ formatoFecha(contract.start) }} ({{ fromNow(contract.start) }})
        </v-col>
        <v-col class="d-flex justify-end align-center">
          <v-btn 
              x-small 
              fab
              @click="openEdit(contract)"
            >
                <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <StateSwitcher 
            :contract="contract" 
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-simple-table>
            <template v-slot:default>
            <thead>
                <tr>
                    <th class="text-left">
                        #Placa
                    </th>
                    <th class="text-left">
                        Parqueo
                    </th>
                    <th class="text-left">
                        Lugar
                    </th>
                    <th class="text-left">
                        Tipo
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="vehi in contract.vehicles" :key="vehi.id">
                    <td>{{ vehi.placa }}</td>
                    <td>{{ vehi.place.parking.title }}</td>
                    <td>
                      <v-chip small>{{ vehi.place.number }}</v-chip>
                    </td>
                    <td>{{ vehi.type_vehi }}</td>
                </tr>
            </tbody>
            </template>
        </v-simple-table>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</v-expansion-panels>
</template>

<script>

import StateSwitcher from '@/components/admin/prepagos/StateSwitcher'
import { mixDate } from '@/mixins/mixDate.js'

export default {
    name: "PrePagosList",
    props: {
        contracts: Array,
    },

    components: {
      StateSwitcher
    },

    methods: {
      openEdit(contract) {
        this.$root.$emit('setContractData',contract)
      }
    },

    mixins: [mixDate],
}

</script>

<style lang="scss">
    .row{
        justify-content: center;
        align-items: center;
    }    
</style>