<template>  
    <v-dialog
      v-model="dialog"
      persistent
      width="450"
    >
      <v-card dark >
        <v-card-title class="darkpage white--text">
          Alerta
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="content">
                <v-icon color="warning" size="6em">mdi-alert</v-icon>
                <div class="left-content">
                    <div class="title">
                      {{ title | titulo }}
                    </div>
                    <div class="caption">
                      {{ title | subtitulo }}
                    </div>
                </div>
            </div>            
          </v-container>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="white--text"
            @click="cerrar"
          >
            Cancelar
          </v-btn>
          <v-btn
            small
            color="success"
            class="darkpage--text"
            rounder
            :loading="loading"
            @click="proceed"
          >
            Proceder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>  
</template>

<script>
import { mixParking } from '@/mixins/mixParking.js'


    export default {
        name: 'ConfirmDialog',
        props: {
            dialog: {
                type: Boolean
            },
            actual: {
                type: Object
            },
            title: {
                type: String
            },
        },
        data() {
          return {
            loading: false,
          }
        },
        filters: {
            titulo: function(value) {
                return value.split(',')[0]
            },
            subtitulo:function(value){
                return value.split(',')[1]
            }
        },
        methods: {
          cerrar(){
            this.$emit('closeConfirm')
          },
          async proceed(){
            this.loading = true
            await this.disableTarifa(this.actual)
            this.loading = false
            this.$emit('closeConfirm')
          }
        },
        mixins: [mixParking],
        
    }
</script>

<style lang="scss">
    .content{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left-content{
            padding: .6em;
        }
    }
</style>