<template>
    <v-card>
        <v-card-text>
            <v-tabs
                v-model="tab"
                align-with-title
                >
                <v-tabs-slider color="yellow"></v-tabs-slider>
    
                <v-tab v-for="parqueo in parqueos" :key="parqueo.title">
                    {{ parqueo.title }}
                </v-tab>
            </v-tabs>
        </v-card-text>
        <v-card-text>
            <v-tabs-items v-model="tab">
                <v-tab-item
                    v-for="parqueo in parqueos"
                    :key="parqueo.id"
                >
                    <div class="boxes">
                        <div class="box-place" v-for="place in parqueo.places" :key="place.id">
                            <template v-if="place.state_place!='contract'">
                                <v-btn x-small fab @click="setPlace(place, parqueo.title)">
                                    {{ place.number }}
                                </v-btn>
                            </template>
                            <template v-else>
                                {{ place.number }}
                            </template>
                        </div>
                    </div>
                </v-tab-item>
            </v-tabs-items>
        </v-card-text>

    </v-card>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'PanelParqueos',
    data() {
        return {
            tab: null,
        }
    },
    computed: {
        ...mapGetters(['parqueos'])
    },
    methods: {
        setPlace(place, title) {
            place.title = title
            this.$emit('addPlace', place)
        }
    },

}
</script>

<style lang="scss">
    .boxes{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        width: 70%;
    }
    .box-place{
        height: 90px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #7ac043;
        border: 2px solid #ccc;
        color: #1a1919;
        position: relative;
    }
</style>