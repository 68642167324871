<template>  
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          color="accent"
          dark
          fab
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="secondary white--text">
          <span class="text-h5">Nuevo Contrato Pre Pago</span>
        </v-card-title>
        <v-card-text>
          <v-row class="pa-4">
            <v-col cols="12" sm="6" md="6">
              <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-row dense>
                  <v-col cols="12" sm="8" md="8">
                    <v-text-field
                      v-model="models.name"
                      label="Nombre Completo"
                      :rules="requiredRule"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="models.ci"
                      label="ID Usuario"
                      :rules="requiredRule"
                      outlined
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="models.mobile"
                      label="Celular"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="models.email"
                      label="E-mail"
                      type="email"
                      outlined
                      :rules="emailRules"
                    ></v-text-field>
                  </v-col>
                  
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                        v-model="dateMenu1"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="models.start"
                            label="Fecha Inicio"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            :rules="requiredRule"
                            readonly
                            outlined
                        ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="models.start"
                          @input="dateMenu1 = false"
                          locale="es"
                        ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                        v-model="dateMenu2"
                        :close-on-content-click="true"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="models.end"
                            label="Fecha Fin"
                            prepend-icon="mdi-calendar"
                            v-bind="attrs"
                            v-on="on"
                            :rules="requiredRule"
                            readonly
                            outlined
                        ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="models.end"
                          @input="dateMenu2 = false"
                          locale="es"
                        ></v-date-picker>
                    </v-menu>
                  </v-col>
  
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                        v-model="models.turno"
                        :items="turnos"
                        label="Turno"
                        :rules="requiredRule"
                        outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="models.chip"
                      label="No Contrato"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
  
                  <v-col cols="12" sm="6" md="6">
                    <v-text-field
                      v-model="models.monto"
                      :rules="requiredRule"
                      label="Monto a Pagar"
                      type="number"
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="6"></v-col>
                </v-row>
              </v-form>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <VehiclesTable 
                  :vehicles="vehicles"
              />
            </v-col>
          </v-row>            
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn            
            text
            @click="cleanData"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            class="darkpage--text"
            rounder
            :loading="loading"
            :disabled="!valid"
            @click="submit"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-snackbar v-model="snackbar" :timeout="6000" bottom color="error">
          <p class="title white--text float-left">{{ mensaje }}</p>
          <v-btn
              small
              icon
              class="darkpage white--text float-right"
              fab
              @click="snackbar = false"
          >x</v-btn>
      </v-snackbar>

    </v-dialog>  
</template>

<script>
import { mapActions } from 'vuex'
import VehiclesTable from '@/components/admin/prepagos/VehiclesTable'

    export default {
        name: "FormNewPrePago",
        data() {
            return {
                dialog: false,
                valid: false,
                lazy: false,
                loading: false,
                dateMenu1: false,
                dateMenu2: false,
                turnos: ['24 horas', 'dia', 'tarde'],
                tipo: 'Mensual',
                tipos: ['Mensual', 'Semestral', 'Anual'],
                vehicles: [],
                models:{
                  name: '',
                  mobile: 0,
                  ci: '',
                  chip: '',
                  email: '',
                  start: null,
                  end: null,
                  turno: '',
                  monto: 0,
                },
                requiredRule: [(v) => !!v || "Este Campo es requerido"],
                emailRules: [
                  (v) => /.+@.+/.test(v) || "E-mail must be valid",
                ],
                snackbar: false,
                mensaje: ''
            }
        },
        components: {
            VehiclesTable
        },
        methods: {
          ...mapActions(['createContract']),
            submit(){
              if(this.valid){
                if(this.vehicles.length>0){
                  this.loading = true
                  let hrs = new Date().getHours()
                  if(hrs<=9){ hrs = '0'+hrs }

                  let mn = new Date().getMinutes()
                  if(mn<=9){ mn = '0'+mn }

                  let sec = new Date().getSeconds()
                  if(sec<=9){ sec = '0'+sec }

                  const horas = hrs+':'+mn+':'+ sec

                  const fdata = {
                    name: this.models.name,
                    ci: this.models.ci,
                    mobile: this.models.mobile,
                    email: this.models.email,
                    start: this.models.start + ' '+ horas,
                    end: this.models.end + ' '+ horas,
                    turno: this.models.turno,
                    chip: this.models.chip,
                    monto: this.models.monto,
                    vehicles: this.vehicles
                  }

                  this.createContract(fdata).then( ()=>{
                    this.loading = false
                    this.cleanData()
                  })

                }else{
                  this.valid = false
                  this.snackbar = true
                  this.mensaje = 'Al menos un Vehiculo es requerido '
                }
              }
            },
            cleanData(){
              this.models.name = ''
              this.models.mobile = 0
              this.models.ci = ''
              this.models.chip = ''
              this.models.email = ''
              this.models.start = null
              this.models.end = null
              this.models.turno = ''
              this.models.monto = 0
              this.vehicles = []
              this.$refs.form.resetValidation()
              this.dialog = false
            }
        },
        created() {
          this.$root.$on("vehiclesAdded", (data) => {
            const place = this.vehicles.find(vehi=>vehi.place_id==data.place_id)            
            if(place==undefined){
              this.vehicles.push(data)
              this.valid = true
            }else{
              this.mensaje = 'Lugar ya se encuentra ocupado'
              this.snackbar = true
            }
          })
          this.$root.$on("vehiclesRemoved", (index) => {
            this.vehicles.splice(index, 1)
          })
	  	  },
    }
</script>

<style lang="scss">
</style>