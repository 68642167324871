<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="6" md="6">
        <DashConfig 
          @updateConfig="setConfig"
        />
      </v-col>
      <v-col cols="12" sm="6" md="6">
        <DashIngresoDia />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <DashTicket
          @updatePSetup="upgratePS"          
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import DashConfig from '@/components/DashConfig'
import DashIngresoDia from '@/components/DashIngresoDia'
import DashTicket from '@/components/DashTicket'

  export default {
    name: 'Admin',
    components: {
      DashConfig,
      DashIngresoDia,
      DashTicket
    },
    methods: {
      ...mapActions(['updateConfig', 'retrieveTarifas', 'updatePrintSetup']),
      setConfig(data){
        this.updateConfig(data)
      },
      upgratePS(data){
        this.updatePrintSetup(data)
      }
    },
    mounted () {
      this.retrieveTarifas()
    },
  }
</script>
