<template>
    <v-card dark class="darkpage" v-if="config!=null">
        <v-card-title class="caption">Config {{config.nombrenegocio}}</v-card-title>
        <v-card-text>
            <v-form ref="form" v-model="valid" :lazy-validation="lazy">
                <v-row dense>
                    <v-col cols="12">
                        <v-text-field
                            v-model="models.nombrenegocio"
                            label="Nombre del Negocio"
                            :rules="requiredRule"
                            :disabled="!edit"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="models.direccion"
                            label="Dirección"
                            :disabled="!edit"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="models.telefono"
                            label="Telefonos"
                            :disabled="!edit"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="models.adicional"
                            label="Tarifa Adicional"
                            :rules="requiredRule"
                            :disabled="!edit"
                            type="number"
                            outlined
                            dense
                        ></v-text-field>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-btn v-if="!proceed" text x-small @click="editCancel">{{ editText }}</v-btn>
            <v-btn
                v-if="edit"
                rounder 
                x-small 
                color="secondary"
                :disabled="!valid"
                @click="proceed=true"
            >Guardar</v-btn>
            <template v-if="proceed">
                <v-spacer></v-spacer>
                <v-btn x-small text @click="proceed=true">Cancelar</v-btn>
                <v-btn
                    dark
                    x-small 
                    rounded
                    color="success"
                    :loading="loading"
                    @click="updateNegocio">Actualizar</v-btn>
            </template>
        </v-card-actions>
    </v-card>
</template>
<script>
    import { mapGetters } from 'vuex'

    export default {        
        name: 'DashConfig',

        data() {
            return {
                valid: false,
                lazy: false,
                edit: false,
                requiredRule: [(v) => !!v || "Este Campo es requerido"],
                editText: 'Editar',
                proceed: false,
                models: {
                    nombrenegocio: '',
                    direccion: '',
                    telefono: '',
                    adicional: 0
                },
                loading: false
            }
        },
        methods: {            
            editCancel() {
                this.edit=!this.edit
                if(!this.edit){
                   this.editText = 'Editar'
                }else{
                    this.editText = 'Cancelar'
                }
                this.$refs.form.resetValidation()
            },
            async updateNegocio(){
                this.loading=true
                await this.$emit('updateConfig', this.models)
                this.loading = false
                this.proceed = false
                this.edit = false
                this.editText = 'Editar'
            }
        },

        computed: {
            ...mapGetters(['config']),
        },

        created (){
            //if(this.config){
            setTimeout(() => {
                this.models.nombrenegocio = this.config.nombrenegocio
                this.models.direccion = this.config.direccion
                this.models.telefono = this.config.telefono
                this.models.adicional = this.config.adicional                
            }, "2000")

            //}
        },
    }
</script>

<style lang="scss" scoped>

</style>