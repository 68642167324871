<template>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
            v-bind="attrs"
            v-on="on"
            x-small
            fab
        >
            <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>

      <v-card dark>
        <v-card-title class="darkpage white--text">
          Alerta
        </v-card-title>
        <v-card-text>
          <v-container>
            <div class="content">
                <v-icon color="warning" size="4em">mdi-alert</v-icon>
                <div class="left-content">
                    <div class="subtitle">
                      ¿Eliminar Usuario ({{ promo.num_placa }}) de la Promoción?
                    </div>
                </div>
            </div>            
          </v-container>
        </v-card-text>
        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="white--text"
            @click="menu=false"
          >
            Cancelar
          </v-btn>
          <v-btn
            small
            color="success"
            class="darkpage--text"
            rounder
            :loading="loading"
            @click="proceed"
          >
            Proceder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
</template>

<script>
import { mixParking } from '@/mixins/mixParking.js'
    export default {
        name: 'DeleteMenu',
        props: {
            promo: {
                type: Object
            },
        },
        data() {
            return {
                fav: true,
                menu: false,
                message: false,
                hints: true,
                loading: false,
            }
        },
        methods: {
            async proceed() {
               this.loading = true
               await this.deletePromo(this.promo)
               this.loading = false
               this.menu = false
               
            }
        },
        mixins: [mixParking],
    }
</script>

<style lang="scss" scoped>

</style>